// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

// TODO: this will move onto kepler.gl core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@kepler.gl/components';
import { validateUrl } from '../../utils/url';

// Define prop types
const propTypes = {
  onLoadRemoteMap: PropTypes.func.isRequired,
};

// Styled Components
const InputForm = styled.div`
  flex-grow: 1;
  padding: 32px;
  background-color: ${(props) => props.theme.panelBackgroundLT};
  margin-bottom: 20px;
`;

const StyledError = styled.div`
  color: red;
  margin-top: 10px;
`;

const StyledErrorDescription = styled.div`
  font-size: 14px;
`;

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
`;

const StyledListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background-color: ${(props) => props.theme.panelBackgroundLT};
  padding: 10px;
  border-radius: 4px;
`;

const ListItemUrl = styled.span`
  flex-grow: 1;
  word-break: break-all;
`;

// Error Component
const Error = ({ error, url }) => (
  <StyledError>
    {url && <StyledErrorDescription>URL: {url}</StyledErrorDescription>}
    <StyledErrorDescription>Error: {error.message}</StyledErrorDescription>
  </StyledError>
);

Error.propTypes = {
  error: PropTypes.object.isRequired,
  url: PropTypes.string,
};

class LoadGeoBitMap extends Component {
  state = {
    dataUrl: '',
    error: null,
    submitted: false,
    files: [], // List of fetched files
    loadingUrls: {}, // Track loading state for each URL
    fetchError: null, // Error while fetching URLs
    isFetching: false, // Loading state for fetching URLs
  };

  componentDidMount() {
    this.fetchFileUrls();
  }

  // Function to fetch the list of file URLs
  fetchFileUrls = async () => {
    const token = sessionStorage.getItem('authToken');
    this.setState({ isFetching: true });
    try {
      const response = await fetch('https://jpn.geobit.ai/load_file_list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: token }),
      });
      if (!response.ok) {
        throw new Error(response.message ?? 'Failed to fetch GeoBitmap URLs');
      }
      const data = await response.json();
      // Assuming the API returns { urls: [...] }
      this.setState({ files: data.files, fetchError: null });
    } catch (error) {
      this.setState({ fetchError: error.message });
    } finally {
      this.setState({ isFetching: false });
    }
  };

  onMapUrlChange = (e) => {
    const value = e.target.value;
    this.setState({
      dataUrl: value,
      error: !validateUrl(value) ? { message: 'Incorrect URL' } : null,
    });
  };

  onLoadRemoteMap = () => {
    const { dataUrl, error } = this.state;

    this.setState({ submitted: true });

    if (!dataUrl || error) {
      return;
    }

    this.props.onLoadRemoteMap({ dataUrl });
  };

  // Function to handle loading a GeoBitmap from a list item
  handleLoadFromList = async (url) => {
    this.setState((prevState) => ({
      loadingUrls: { ...prevState.loadingUrls, [url]: true },
    }));

    try {
      await this.props.onLoadRemoteMap({ dataUrl: url });
    } catch (error) {
      // Handle error if needed
      console.error(`Error loading files from ${url}:`, error);
      this.setState((prevState) => ({
        loadingUrls: { ...prevState.loadingUrls, [url]: false },
        error: { message: `Failed to load GeoBitmap from ${url}` },
      }));
    } finally {
      this.setState((prevState) => ({
        loadingUrls: { ...prevState.loadingUrls, [url]: false },
      }));
    }
  };

  render() {
    const {
      error,
      submitted,
      files,
      loadingUrls,
      fetchError,
      isFetching,
      dataUrl,
    } = this.state;

    return (
      <div>
        {/* List of Available GeoBitmaps */}
        <InputForm>
          <h3>Available GeoBit Files</h3>
          {isFetching && <p>Loading Files From GeoBit...</p>}
          {fetchError && <StyledError>{fetchError}</StyledError>}
          {!isFetching && !fetchError && files.length === 0 && (
            <p>No Files available.</p>
          )}
          <StyledList>
            {files.map((file) => (
              // <StyledFromGroup>
              // <StyledInput
              //   onChange={this.onMapUrlChange}
              //   type="text"
              //   placeholder="Url"
              //   value={this.state.dataUrl}
              //   error={displayedError}
              // />
              // <Button type="submit" cta size="small" onClick={this.onLoadRemoteMap}>
              //   <FormattedMessage id={'loadRemoteMap.fetch'} />
              // </Button>
              // </StyledFromGroup>
              <StyledListItem key={file.file_url}>
                <ListItemUrl>{file.file_url}</ListItemUrl>
                <Button
                  type="button"
                  size="small"
                  onClick={() => this.handleLoadFromList(file.file_url)}
                  disabled={loadingUrls[file.file_url]}
                >
                  {loadingUrls[file.file_url] ? 'Loading...' : 'Load'}
                </Button>
              </StyledListItem>
            ))}
          </StyledList>
        </InputForm>
      </div>
    );
  }
}

LoadGeoBitMap.propTypes = propTypes;

export default LoadGeoBitMap;
