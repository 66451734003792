// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

export default {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        vendor: 'A',
        value: 10
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [-73.78966, 40.6429, 0, 1565578338],
          [-73.7895, 40.64267, 0, 1565578346],
          [-73.78923, 40.6424, 0, 1565578356],
          [-73.78905, 40.64222, 0, 1565578364],
          [-73.7889, 40.64209, 0, 1565578365],
          [-73.78859, 40.64191, 0, 1565578367],
          [-73.78836, 40.64181, 0, 1565578368],
          [-73.78793, 40.6417, 0, 1565578371],
          [-73.78756, 40.64163, 0, 1565578373],
          [-73.78707, 40.64159, 0, 1565578375],
          [-73.78674, 40.64161, 0, 1565578377],
          [-73.78659, 40.64166, 0, 1565578378],
          [-73.78646, 40.64172, 0, 1565578379],
          [-73.78633, 40.64182, 0, 1565578380],
          [-73.78612, 40.6422, 0, 1565578382],
          [-73.78607, 40.64244, 0, 1565578384],
          [-73.7861, 40.64263, 0, 1565578385],
          [-73.78621, 40.64305, 0, 1565578388],
          [-73.78621, 40.64323, 0, 1565578390],
          [-73.78619, 40.64337, 0, 1565578391],
          [-73.78612, 40.6435, 0, 1565578392],
          [-73.78586, 40.64382, 0, 1565578394],
          [-73.78583, 40.64388, 0, 1565578395],
          [-73.78582, 40.64397, 0, 1565578395],
          [-73.78585, 40.64404, 0, 1565578396],
          [-73.78655, 40.64488, 0, 1565578403],
          [-73.78666, 40.64505, 0, 1565578404],
          [-73.78685, 40.64546, 0, 1565578407],
          [-73.7869, 40.64571, 0, 1565578409],
          [-73.7869, 40.64592, 0, 1565578410],
          [-73.78687, 40.64619, 0, 1565578412],
          [-73.78677, 40.64634, 0, 1565578413],
          [-73.78668, 40.64643, 0, 1565578414],
          [-73.78658, 40.64649, 0, 1565578415],
          [-73.78645, 40.64655, 0, 1565578416],
          [-73.78629, 40.64657, 0, 1565578416],
          [-73.78613, 40.64656, 0, 1565578417],
          [-73.786, 40.64653, 0, 1565578418],
          [-73.78588, 40.64647, 0, 1565578419],
          [-73.78578, 40.6464, 0, 1565578419],
          [-73.7857, 40.6463, 0, 1565578420],
          [-73.78566, 40.64622, 0, 1565578421],
          [-73.78565, 40.64607, 0, 1565578422],
          [-73.78574, 40.64584, 0, 1565578423],
          [-73.78588, 40.64565, 0, 1565578425],
          [-73.78611, 40.64547, 0, 1565578427],
          [-73.78638, 40.64534, 0, 1565578428],
          [-73.78677, 40.6452, 0, 1565578431],
          [-73.78702, 40.64514, 0, 1565578432],
          [-73.78744, 40.64505, 0, 1565578434],
          [-73.7878, 40.645, 0, 1565578436],
          [-73.78831, 40.64496, 0, 1565578439],
          [-73.79014, 40.64495, 0, 1565578449]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        vendor: 'B',
        value: 4
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [-74.33223, 40.64375, 0, 1565578213],
          [-74.33242, 40.64353, 0, 1565578217],
          [-74.33001, 40.64222, 0, 1565578243],
          [-74.32882, 40.64154, 0, 1565578256],
          [-74.32682, 40.64039, 0, 1565578278],
          [-74.32589, 40.63985, 0, 1565578288],
          [-74.31725, 40.63485, 0, 1565578382],
          [-74.31404, 40.63302, 0, 1565578417],
          [-74.30616, 40.6283, 0, 1565578504],
          [-74.30592, 40.62814, 0, 1565578506],
          [-74.3056, 40.62783, 0, 1565578511],
          [-74.30549, 40.62763, 0, 1565578513],
          [-74.30536, 40.62722, 0, 1565578518],
          [-74.30524, 40.62704, 0, 1565578521],
          [-74.30511, 40.62689, 0, 1565578523],
          [-74.30447, 40.62623, 0, 1565578532],
          [-74.30409, 40.62652, 0, 1565578536],
          [-74.30374, 40.62689, 0, 1565578541],
          [-74.30328, 40.62753, 0, 1565578549],
          [-74.30297, 40.6279, 0, 1565578553],
          [-74.30174, 40.6292, 0, 1565578570],
          [-74.29912, 40.63154, 0, 1565578585],
          [-74.29855, 40.63202, 0, 1565578588],
          [-74.29796, 40.63249, 0, 1565578591],
          [-74.29508, 40.63461, 0, 1565578606],
          [-74.29448, 40.63508, 0, 1565578610],
          [-74.29409, 40.6354, 0, 1565578612],
          [-74.29373, 40.63575, 0, 1565578614],
          [-74.29322, 40.63626, 0, 1565578617],
          [-74.29209, 40.63753, 0, 1565578624],
          [-74.29086, 40.63902, 0, 1565578633],
          [-74.28998, 40.64015, 0, 1565578639],
          [-74.28944, 40.64091, 0, 1565578644],
          [-74.28908, 40.64151, 0, 1565578647],
          [-74.2888, 40.64201, 0, 1565578649],
          [-74.2883, 40.64303, 0, 1565578655],
          [-74.28789, 40.64408, 0, 1565578660],
          [-74.28762, 40.64494, 0, 1565578664],
          [-74.28745, 40.6457, 0, 1565578668],
          [-74.28733, 40.64636, 0, 1565578671],
          [-74.28723, 40.64724, 0, 1565578676],
          [-74.28717, 40.64812, 0, 1565578680],
          [-74.28712, 40.65661, 0, 1565578721],
          [-74.28709, 40.66022, 0, 1565578739],
          [-74.28705, 40.6611, 0, 1565578743],
          [-74.28698, 40.66175, 0, 1565578746],
          [-74.28687, 40.6624, 0, 1565578750],
          [-74.28667, 40.66327, 0, 1565578754],
          [-74.28642, 40.6641, 0, 1565578758],
          [-74.28619, 40.66472, 0, 1565578761],
          [-74.28573, 40.66576, 0, 1565578766],
          [-74.2853, 40.66657, 0, 1565578771],
          [-74.28506, 40.66697, 0, 1565578773],
          [-74.28434, 40.66804, 0, 1565578779],
          [-74.28077, 40.67287, 0, 1565578806]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        vendor: 'A',
        value: 7
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [-73.87893, 40.64672, 0, 1565578095],
          [-73.87969, 40.64624, 0, 1565578123],
          [-73.87976, 40.64619, 0, 1565578125],
          [-73.88064, 40.64697, 0, 1565578156],
          [-73.88138, 40.64765, 0, 1565578181],
          [-73.88234, 40.64849, 0, 1565578214],
          [-73.883, 40.64911, 0, 1565578237],
          [-73.88338, 40.64943, 0, 1565578250],
          [-73.88349, 40.64941, 0, 1565578252],
          [-73.88411, 40.64995, 0, 1565578273],
          [-73.8848, 40.65076, 0, 1565578302],
          [-73.88624, 40.6527, 0, 1565578367],
          [-73.88632, 40.65281, 0, 1565578371],
          [-73.88747, 40.65232, 0, 1565578386],
          [-73.88798, 40.6521, 0, 1565578393],
          [-73.88896, 40.65341, 0, 1565578430],
          [-73.88978, 40.65306, 0, 1565578442],
          [-73.89059, 40.65271, 0, 1565578454],
          [-73.89413, 40.65747, 0, 1565578588],
          [-73.89424, 40.65762, 0, 1565578593],
          [-73.89595, 40.65786, 0, 1565578627],
          [-73.89613, 40.65785, 0, 1565578630],
          [-73.89616, 40.65796, 0, 1565578633],
          [-73.898, 40.65768, 0, 1565578661],
          [-73.8988, 40.65753, 0, 1565578673],
          [-73.89955, 40.65738, 0, 1565578684],
          [-73.90021, 40.65728, 0, 1565578695],
          [-73.90039, 40.65724, 0, 1565578698],
          [-73.90051, 40.65719, 0, 1565578700],
          [-73.90063, 40.65712, 0, 1565578703],
          [-73.90076, 40.65702, 0, 1565578706],
          [-73.90118, 40.6566, 0, 1565578717],
          [-73.90135, 40.65646, 0, 1565578721],
          [-73.90282, 40.65553, 0, 1565578751],
          [-73.90299, 40.65544, 0, 1565578755],
          [-73.90319, 40.65536, 0, 1565578758],
          [-73.90321, 40.65546, 0, 1565578761],
          [-73.90337, 40.65542, 0, 1565578765],
          [-73.90616, 40.65501, 0, 1565578836]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        vendor: 'A',
        value: 11
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [-74.18572, 40.68929, 0, 1565577803],
          [-74.1858, 40.68924, 0, 1565577808],
          [-74.18564, 40.68909, 0, 1565577821],
          [-74.18529, 40.68929, 0, 1565577830],
          [-74.18486, 40.68954, 0, 1565577841],
          [-74.18482, 40.68982, 0, 1565577849],
          [-74.18481, 40.691, 0, 1565577882],
          [-74.18484, 40.69121, 0, 1565577888],
          [-74.18487, 40.6913, 0, 1565577891],
          [-74.18494, 40.69143, 0, 1565577895],
          [-74.18506, 40.69157, 0, 1565577900],
          [-74.18531, 40.69177, 0, 1565577907],
          [-74.18612, 40.69232, 0, 1565577931],
          [-74.18633, 40.69247, 0, 1565577937],
          [-74.18641, 40.69259, 0, 1565577941],
          [-74.18643, 40.69267, 0, 1565577943],
          [-74.18644, 40.69272, 0, 1565577944],
          [-74.18641, 40.69288, 0, 1565577946],
          [-74.18636, 40.69297, 0, 1565577947],
          [-74.18582, 40.69346, 0, 1565577954],
          [-74.18532, 40.69402, 0, 1565577961],
          [-74.18499, 40.6945, 0, 1565577967]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        vendor: 'B',
        value: 6
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [-74.00823, 40.71351, 0, 1565577838],
          [-74.00884, 40.7138, 0, 1565577867],
          [-74.00668, 40.71653, 0, 1565577947],
          [-74.00625, 40.71708, 0, 1565577962],
          [-74.00753, 40.71766, 0, 1565577996],
          [-74.00888, 40.71827, 0, 1565578032],
          [-74.00869, 40.71929, 0, 1565578060],
          [-74.00858, 40.71996, 0, 1565578077],
          [-74.01191, 40.72029, 0, 1565578205],
          [-74.01204, 40.72031, 0, 1565578210],
          [-74.01097, 40.72554, 0, 1565578295],
          [-74.01078, 40.72677, 0, 1565578315],
          [-74.01008, 40.73394, 0, 1565578431],
          [-74.00983, 40.73694, 0, 1565578480],
          [-74.00979, 40.73746, 0, 1565578488],
          [-74.00989, 40.73851, 0, 1565578505],
          [-74.0099, 40.73899, 0, 1565578513],
          [-74.00985, 40.73929, 0, 1565578518],
          [-74.0098, 40.73942, 0, 1565578520],
          [-74.00967, 40.73971, 0, 1565578525],
          [-74.00928, 40.74033, 0, 1565578536],
          [-74.00913, 40.74065, 0, 1565578541],
          [-74.00906, 40.74081, 0, 1565578544],
          [-74.00887, 40.74154, 0, 1565578556],
          [-74.00871, 40.74219, 0, 1565578567],
          [-74.00868, 40.74235, 0, 1565578569],
          [-74.00837, 40.74395, 0, 1565578595],
          [-74.00803, 40.74588, 0, 1565578625],
          [-74.00773, 40.7475, 0, 1565578651],
          [-74.00769, 40.74775, 0, 1565578654],
          [-74.00762, 40.74845, 0, 1565578668],
          [-74.00768, 40.7487, 0, 1565578672],
          [-74.00777, 40.74892, 0, 1565578677],
          [-74.00811, 40.74959, 0, 1565578690],
          [-74.0082, 40.7498, 0, 1565578694],
          [-74.00825, 40.75003, 0, 1565578699],
          [-74.00827, 40.75029, 0, 1565578703],
          [-74.00825, 40.75055, 0, 1565578708],
          [-74.00699, 40.75388, 0, 1565578773],
          [-74.00664, 40.75442, 0, 1565578784],
          [-74.00336, 40.75891, 0, 1565578881]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        vendor: 'A',
        value: 1
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [-73.97301, 40.67601, 0, 1565578666],
          [-73.97161, 40.67546, 0, 1565578694],
          [-73.97142, 40.67575, 0, 1565578712],
          [-73.9719, 40.67641, 0, 1565578732],
          [-73.97244, 40.67716, 0, 1565578756],
          [-73.97234, 40.67744, 0, 1565578778],
          [-73.96917, 40.67678, 0, 1565578836]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        vendor: 'A',
        value: 7
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [-74.03806, 40.74578, 0, 1565578252],
          [-74.03893, 40.74289, 0, 1565578322],
          [-74.03934, 40.74158, 0, 1565578354],
          [-74.0384, 40.74142, 0, 1565578376],
          [-74.03746, 40.74126, 0, 1565578399],
          [-74.03824, 40.73872, 0, 1565578465],
          [-74.03878, 40.73693, 0, 1565578511],
          [-74.03893, 40.73693, 0, 1565578513],
          [-74.03974, 40.73704, 0, 1565578524],
          [-74.03979, 40.7369, 0, 1565578528],
          [-74.03964, 40.73677, 0, 1565578531],
          [-74.03916, 40.73685, 0, 1565578538],
          [-74.03881, 40.73686, 0, 1565578546],
          [-74.03854, 40.73684, 0, 1565578553],
          [-74.03864, 40.73655, 0, 1565578562],
          [-74.03879, 40.73571, 0, 1565578589],
          [-74.03943, 40.73155, 0, 1565578722],
          [-74.03954, 40.7308, 0, 1565578746],
          [-74.03791, 40.73066, 0, 1565578753],
          [-74.03782, 40.73065, 0, 1565578754],
          [-74.03789, 40.73024, 0, 1565578763],
          [-74.03795, 40.72999, 0, 1565578769],
          [-74.03797, 40.72988, 0, 1565578770],
          [-74.03798, 40.7298, 0, 1565578771],
          [-74.0368, 40.7298, 0, 1565578784],
          [-74.03646, 40.72977, 0, 1565578788],
          [-74.03613, 40.72971, 0, 1565578791],
          [-74.03604, 40.72969, 0, 1565578792],
          [-74.03222, 40.72883, 0, 1565578822]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        vendor: 'B',
        value: 15
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [-73.79007, 40.64681, 0, 1565577697],
          [-73.79092, 40.64643, 0, 1565577720],
          [-73.79108, 40.64631, 0, 1565577726],
          [-73.7912, 40.64613, 0, 1565577732],
          [-73.79121, 40.64597, 0, 1565577737],
          [-73.79116, 40.64581, 0, 1565577743],
          [-73.79106, 40.6457, 0, 1565577747],
          [-73.79094, 40.64557, 0, 1565577749],
          [-73.79072, 40.64542, 0, 1565577751],
          [-73.79057, 40.64534, 0, 1565577753],
          [-73.7904, 40.64527, 0, 1565577754],
          [-73.79022, 40.64522, 0, 1565577755],
          [-73.78974, 40.64522, 0, 1565577758],
          [-73.78916, 40.64528, 0, 1565577760],
          [-73.78889, 40.64534, 0, 1565577762],
          [-73.78855, 40.64545, 0, 1565577764],
          [-73.78827, 40.64558, 0, 1565577765],
          [-73.78818, 40.6456, 0, 1565577766],
          [-73.788, 40.64562, 0, 1565577767],
          [-73.78793, 40.64561, 0, 1565577767],
          [-73.7878, 40.64556, 0, 1565577768],
          [-73.78772, 40.64545, 0, 1565577769],
          [-73.78771, 40.64534, 0, 1565577769],
          [-73.78775, 40.64524, 0, 1565577770],
          [-73.78784, 40.64518, 0, 1565577771],
          [-73.78799, 40.64513, 0, 1565577771],
          [-73.78984, 40.64512, 0, 1565577781],
          [-73.79041, 40.64509, 0, 1565577784],
          [-73.79124, 40.64508, 0, 1565577788],
          [-73.79167, 40.64506, 0, 1565577790],
          [-73.79193, 40.64508, 0, 1565577791],
          [-73.79313, 40.64494, 0, 1565577797],
          [-73.79412, 40.64485, 0, 1565577802],
          [-73.79437, 40.64481, 0, 1565577804],
          [-73.79472, 40.64474, 0, 1565577805],
          [-73.79569, 40.64469, 0, 1565577808],
          [-73.79623, 40.64469, 0, 1565577810],
          [-73.79671, 40.64472, 0, 1565577811],
          [-73.79722, 40.64478, 0, 1565577813],
          [-73.79768, 40.64486, 0, 1565577814],
          [-73.79813, 40.64496, 0, 1565577816],
          [-73.79865, 40.64511, 0, 1565577817],
          [-73.79896, 40.64522, 0, 1565577818],
          [-73.79953, 40.64545, 0, 1565577820],
          [-73.80022, 40.64582, 0, 1565577823],
          [-73.80062, 40.64606, 0, 1565577824],
          [-73.80102, 40.64636, 0, 1565577826],
          [-73.80178, 40.64703, 0, 1565577829],
          [-73.80245, 40.64766, 0, 1565577832],
          [-73.80314, 40.64837, 0, 1565577836],
          [-73.80379, 40.64909, 0, 1565577839],
          [-73.80479, 40.65031, 0, 1565577845],
          [-73.80528, 40.65096, 0, 1565577848],
          [-73.80578, 40.65166, 0, 1565577851],
          [-73.80623, 40.65236, 0, 1565577854],
          [-73.80658, 40.65294, 0, 1565577856],
          [-73.80681, 40.65342, 0, 1565577858],
          [-73.80702, 40.65396, 0, 1565577860],
          [-73.80718, 40.65449, 0, 1565577862],
          [-73.8073, 40.65502, 0, 1565577864],
          [-73.80738, 40.6557, 0, 1565577867],
          [-73.80731, 40.65585, 0, 1565577868],
          [-73.80719, 40.65642, 0, 1565577873],
          [-73.8071, 40.6567, 0, 1565577876],
          [-73.807, 40.65692, 0, 1565577878],
          [-73.80687, 40.6571, 0, 1565577880],
          [-73.80672, 40.65725, 0, 1565577881],
          [-73.80641, 40.65752, 0, 1565577884],
          [-73.80622, 40.65766, 0, 1565577886],
          [-73.80606, 40.65781, 0, 1565577887],
          [-73.80593, 40.65799, 0, 1565577888]
        ]
      }
    }
  ]
};

export const animateTripDataId = 'animate-trip-data';
export const pointDataId = 'sample_taxi_trip';

// animationConfig,domain 1565577697000, 1565578881000
// filer.domain: 1565576422000, 1565577120000
export const pointData = {
  fields: [
    {
      name: 'tpep_pickup_datetime',
      format: 'YYYY-M-D H:m:s',
      type: 'timestamp'
    },
    {name: 'pickup_longitude', format: '', type: 'real'},
    {name: 'pickup_latitude', format: '', type: 'real'}
  ],
  rows: [
    ['2019-08-12 2:23:42', -73.99389648, 40.75011063],
    ['2019-08-12 2:32:00', -73.97642517, 40.73981094],
    ['2019-08-12 2:21:00', -73.96870422, 40.75424576],
    ['2019-08-12 2:28:18', -73.86306, 40.76958084],
    ['2019-08-12 2:20:36', -73.94554138, 40.77942276],
    ['2019-08-12 2:20:22', -73.87445831, 40.7740097],
    ['2019-08-12 2:31:00', -73.97660065, 40.7518959]
  ]
};

export const replacePointData = {
  fields: pointData.fields,
  rows: [
    ['2019-08-12 2:23:18', -73.97812653, 40.75257492],
    ['2019-08-12 2:07:26', -74.00622559, 40.73387146],
    ['2019-08-12 2:08:55', -73.97122955, 40.75518417],
    ['2019-08-12 2:10:30', -73.97627258, 40.75893402]
  ]
};
