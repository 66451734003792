// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

// Sample Taxi Trip Data
export const testCsvData = `gps_data.utc_timestamp,gps_data.lat,gps_data.lng,gps_data.types,epoch,has_result,id,time,begintrip_ts_utc,begintrip_ts_local,date,<img>_tooltip,website
2016-09-17 00:09:55,29.9900937,31.2590542,driver_analytics,1472688000000,False,1,2016-09-23T00:00:00.000Z,2016-10-01 09:41:39+00:00,2016-10-01 09:41:39+00:00,2016-09-23,https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/icon-demo-map.jpg,http://kepler.gl
2016-09-17 00:10:56,29.9927699,31.2461142,driver_analytics,1472688000000,False,2,2016-09-23T00:00:00.000Z,2016-10-01 09:46:37+00:00,2016-10-01 16:46:37+00:00,2016-09-23,https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/icon-demo-map.jpg,http://kepler.gl
2016-09-17 00:11:56,29.9907261,31.2312742,driver_analytics,1472688000000,False,3,2016-09-23T00:00:00.000Z,,,2016-09-23,https://user-images.githubusercontent.com/3605556/66859873-f2aed400-ef40-11e9-8a41-d07de7560376.png,http://kepler.gl
2016-09-17 00:12:58,29.9870074,31.2175827,driver_analytics,1472688000000,False,4,2016-09-23T00:00:00.000Z,,,2016-09-23,https://user-images.githubusercontent.com/3605556/66859873-f2aed400-ef40-11e9-8a41-d07de7560376.png,http://kepler.gl
2016-09-17 00:14:00,29.9923041,31.2154899,driver_analytics,1472688000000,False,5,2016-09-23T00:00:00.000Z,2016-10-01 09:47:37+00:00,2016-10-01 16:47:37+00:00,,https://user-images.githubusercontent.com/3605556/66859873-f2aed400-ef40-11e9-8a41-d07de7560376.png,http://kepler.gl
2016-09-17 00:15:01,29.9968249,31.2149361,driver_analytics,1472688000000,False,12124,2016-09-23T05:00:00.000Z,,,,https://user-images.githubusercontent.com/3605556/66859873-f2aed400-ef40-11e9-8a41-d07de7560376.png,http://kepler.gl
2016-09-17 00:16:03,30.0037217,31.2164035,driver_analytics,1472688000000,False,222,2016-09-23T05:00:00.000Z,,,,https://user-images.githubusercontent.com/3605556/66859873-f2aed400-ef40-11e9-8a41-d07de7560376.png,http://kepler.gl
2016-09-17 00:17:05,30.0116207,31.2179346,driver_analytics,1472688000000,False,345,2016-09-23T00:00:00.000Z,,,2016-09-24,https://user-images.githubusercontent.com/3605556/66859873-f2aed400-ef40-11e9-8a41-d07de7560376.png,http://kepler.gl
2016-09-17 00:18:09,30.0208925,31.2179556,driver_analytics,1472708000000,False,,2016-09-23T00:00:00.000Z,,,2016-09-24,https://user-images.githubusercontent.com/3605556/66859873-f2aed400-ef40-11e9-8a41-d07de7560376.png,http://kepler.gl
2016-09-17 00:19:12,30.0218999,31.2178842,driver_analytics,1472708000000,False,,2016-09-23T06:00:00.000Z,,,2016-09-24,https://user-images.githubusercontent.com/3605556/66859873-f2aed400-ef40-11e9-8a41-d07de7560376.png,http://kepler.gl
2016-09-17 00:19:27,30.0229344,31.2179138,driver_analytics,1472708000000,False,,2016-09-23T05:00:00.000Z,,,2016-09-24,https://user-images.githubusercontent.com/3605556/66859873-f2aed400-ef40-11e9-8a41-d07de7560376.png,http://kepler.gl
2016-09-17 00:20:14,30.0264237,31.2179415,driver_analytics,1472708000000,False,,,,,2016-09-24,https://user-images.githubusercontent.com/3605556/66859873-f2aed400-ef40-11e9-8a41-d07de7560376.png,http://kepler.gl
2016-09-17 00:21:17,30.0292134,31.2181809,driver_analytics,1472754400000,False,,,,,2016-09-24,https://user-images.githubusercontent.com/3605556/66859873-f2aed400-ef40-11e9-8a41-d07de7560376.png,http://kepler.gl
2016-09-17 00:22:20,30.034391,31.2193991,driver_analytics,1472754400000,,,2016-09-23T06:00:00.000Z,,,,https://user-images.githubusercontent.com/3605556/66859873-f2aed400-ef40-11e9-8a41-d07de7560376.png,http://kepler.gl
2016-09-17 00:23:22,30.0352752,31.2181803,driver_analytics,1472754400000,,,2016-09-23T06:00:00.000Z,2016-10-01 10:01:54+00:00,2016-10-01 17:01:54+00:00,,https://user-images.githubusercontent.com/3605556/66859873-f2aed400-ef40-11e9-8a41-d07de7560376.png,http://kepler.gl
2016-09-17 00:24:24,30.0395918,31.2195902,driver_analytics,1472754400000,,1,2016-09-23T00:00:00.000Z,2016-10-01 09:53:04+00:00,2016-10-01 16:53:04+00:00,,https://user-images.githubusercontent.com/3605556/66859873-f2aed400-ef40-11e9-8a41-d07de7560376.png,http://kepler.gl
2016-09-17 00:25:28,30.0497387,31.2174421,driver_analytics,1472774400000,,,2016-09-23T07:00:00.000Z,2016-10-01 09:55:23+00:00,2016-10-01 16:55:23+00:00,,https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/icon-demo-map.jpg,http://kepler.gl
2016-09-17 00:26:29,30.0538936,31.2165983,driver_analytics,1472774400000,,43,2016-09-23T07:00:00.000Z,2016-10-01 09:59:53+00:00,2016-10-01 16:59:53+00:00,2016-10-10,https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/icon-demo-map.jpg,http://kepler.gl
2016-09-17 00:27:31,30.060911,31.2148748,driver_analytics,1472774400000,,4,2016-09-23T07:00:00.000Z,2016-10-01 09:57:11+00:00,2016-10-01 16:57:11+00:00,2016-10-10,https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/icon-demo-map.jpg,http://kepler.gl
2016-09-17 00:28:35,30.060334,31.2212278,driver_analytics,1472774400000,,5,2016-09-23T07:00:00.000Z,2016-10-01 09:59:27+00:00,2016-10-01 16:59:27+00:00,2016-10-10,https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/icon-demo-map.jpg,http://kepler.gl
2016-09-17 00:29:40,30.0554663,31.2288985,driver_analytics,1472774400000,True,,2016-09-23T07:00:00.000Z,2016-10-01 09:46:36+00:00,2016-10-01 16:46:36+00:00,2016-10-10,https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/icon-demo-map.jpg,http://kepler.gl
2016-09-17 00:30:03,30.0614122,31.2187021,driver_gps,1472774400000,True,6,2016-09-23T08:00:00.000Z,2016-10-01 09:54:31+00:00,2016-10-01 16:54:31+00:00,2016-10-10,https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/icon-demo-map.jpg,http://kepler.gl
2016-09-17 00:30:03,30.0612697,31.2191059,driver_gps,1472774400000,True,7,2016-09-23T08:00:00.000Z,2016-10-01 09:53:35+00:00,2016-10-01 16:53:35+00:00,2016-10-10,https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/icon-demo-map.jpg,http://kepler.gl
2016-09-17 00:30:08,30.0610977,31.2194728,driver_gps,1472774400000,True,,2016-09-23T08:00:00.000Z,,,,https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/icon-demo-map.jpg,http://kepler.gl
`;

export default {
  fields: [
    {
      name: 'tpep_pickup_datetime',
      format: 'YYYY-M-D H:m:s',
      type: 'timestamp'
    },
    {
      name: 'tpep_dropoff_datetime',
      format: 'YYYY-M-D H:m:s',
      type: 'timestamp'
    },
    {
      name: 'passenger_count',
      format: '',
      type: 'integer'
    },
    {name: 'trip_distance', format: '', type: 'real'},
    {name: 'pickup_longitude', format: '', type: 'real'},
    {name: 'pickup_latitude', format: '', type: 'real'},
    {name: 'dropoff_longitude', format: '', type: 'real'},
    {name: 'dropoff_latitude', format: '', type: 'real'},
    {name: 'fare_amount', format: '', type: 'real'},
    {name: 'is_completed', format: '', type: 'boolean'},
    {name: 'fare_type', format: '', type: 'string'}
  ],
  rows: [
    [
      null,
      '2015-01-15 19:23:42 +00:00',
      null,
      1.59,
      -73.99389648,
      40.75011063,
      -73.97478485,
      40.75061798,
      12,
      true,
      'orange peel 0'
    ],
    [
      null,
      '2015-01-15 19:32:00 +00:00',
      -3,
      2.38,
      -73.97642517,
      40.73981094,
      -73.98397827,
      40.75788879,
      16.5,
      false,
      'orange peel 0'
    ],
    [
      '2015-01-15 19:05:40 +00:00',
      '2015-01-15 19:21:00 +00:00',
      5,
      2.83,
      -73.96870422,
      40.75424576,
      -73.9551239,
      40.7868576,
      12.5,
      false,
      'apple tree'
    ],
    [
      null,
      '2015-01-15 19:28:18 +00:00',
      5,
      8.33,
      -73.86306,
      40.76958084,
      -73.95271301,
      40.78578186,
      26,
      true,
      'orange peel 0'
    ],
    [
      '2015-01-15 19:05:41 +00:00',
      '2015-01-15 19:20:36 +00:00',
      1,
      2.37,
      -73.94554138,
      40.77942276,
      -73.98085022,
      40.78608322,
      11.5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 19:05:41 +00:00',
      '2015-01-15 19:20:22 +00:00',
      2,
      7.13,
      -73.87445831,
      40.7740097,
      -73.95237732,
      40.71858978,
      21.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 19:05:41 +00:00',
      '2015-01-15 19:31:00 +00:00',
      0,
      3.6,
      -73.97660065,
      40.7518959,
      -73.99892426,
      40.71459579,
      17.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 19:05:41 +00:00',
      '2015-01-15 19:10:22 +00:00',
      1,
      0.89,
      -73.99495697,
      40.74507904,
      -73.99993896,
      40.73464966,
      5.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 19:05:41 +00:00',
      '2015-01-15 19:10:55 +00:00',
      1,
      0.96,
      -74.00093842,
      40.74706268,
      -74.00356293,
      40.73551178,
      5.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 19:05:41 +00:00',
      '2015-01-15 19:12:36 +00:00',
      2,
      1.25,
      -74.0027771,
      40.71789169,
      -74.00791931,
      40.70421982,
      6.5,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 19:05:41 +00:00',
      '2015-01-15 19:22:11 +00:00',
      5,
      2.11,
      -73.99745941,
      40.73636246,
      -73.9781723,
      40.76185608,
      11.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 19:05:41 +00:00',
      '2015-01-15 19:14:05 +00:00',
      5,
      1.15,
      -73.95227814,
      40.82399368,
      -73.95333862,
      40.81108856,
      7.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 19:05:42 +00:00',
      '2015-01-15 19:16:18 +00:00',
      0,
      1.53,
      -73.99112701,
      40.75008011,
      -73.98860931,
      40.73488998,
      9,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 19:05:42 +00:00',
      '2015-01-15 19:49:07 +00:00',
      1,
      18.06,
      -73.78657532,
      40.64412689,
      -73.98560333,
      40.74353027,
      52,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 19:05:42 +00:00',
      '2015-01-15 19:18:33 +00:00',
      1,
      1.76,
      -73.9936676,
      40.74144745,
      -73.99451447,
      40.75772095,
      10,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 19:05:42 +00:00',
      '2015-01-15 19:21:40 +00:00',
      6,
      5.19,
      -73.98529053,
      40.7440834,
      -74.00907898,
      40.70468903,
      17.5,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 19:05:42 +00:00',
      '2015-01-15 19:14:23 +00:00',
      1,
      1.38,
      -73.96977234,
      40.7856102,
      -73.95070648,
      40.77468109,
      7.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 19:05:43 +00:00',
      '2015-01-15 19:17:43 +00:00',
      -3,
      2.23,
      -73.98509216,
      40.75698853,
      -73.98487854,
      40.78015137,
      10,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 19:05:43 +00:00',
      '2015-01-15 19:15:17 +00:00',
      1,
      1.56,
      -73.96616364,
      40.76216125,
      -73.95005035,
      40.77625275,
      8,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 19:05:43 +00:00',
      '2015-01-15 19:15:09 +00:00',
      5,
      1.57,
      -73.97842407,
      40.74620819,
      -73.98021698,
      40.72854233,
      8,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 19:05:44 +00:00',
      '2015-01-15 19:17:44 +00:00',
      1,
      2.92,
      -73.99835205,
      40.72003174,
      -73.95582581,
      40.71559906,
      12.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 14:00:41 +00:00',
      '2015-01-15 14:11:18 +00:00',
      1,
      1.64,
      -73.9837265,
      40.74634171,
      -73.96679688,
      40.76140594,
      8.5,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 14:00:41 +00:00',
      '2015-01-15 14:19:26 +00:00',
      1,
      1.53,
      -73.99578094,
      40.73294067,
      -73.99107361,
      40.75038147,
      12,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 14:00:41 +00:00',
      '2015-01-15 14:31:42 +00:00',
      -5,
      7.48,
      -73.87081909,
      40.77370071,
      -73.96869659,
      40.76268005,
      27,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 14:00:41 +00:00',
      '2015-01-15 14:13:48 +00:00',
      1,
      3,
      -73.96427155,
      40.7730217,
      -73.96577454,
      40.80466843,
      12,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 14:00:42 +00:00',
      '2015-01-15 14:05:01 +00:00',
      1,
      0.67,
      -73.97093201,
      40.79592133,
      -73.97016907,
      40.78912354,
      5,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 14:00:42 +00:00',
      '2015-01-15 14:20:40 +00:00',
      1,
      1.51,
      -73.97212219,
      40.75902939,
      -73.99093628,
      40.7557373,
      13,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 14:00:42 +00:00',
      '2015-01-15 14:05:16 +00:00',
      2,
      0.85,
      -73.98056793,
      40.7656517,
      -73.97304535,
      40.75827026,
      5.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 14:00:43 +00:00',
      '2015-01-15 14:58:40 +00:00',
      -1,
      15.2,
      -73.8012619,
      40.66772842,
      -73.9933548,
      40.75600052,
      52,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 14:00:43 +00:00',
      '2015-01-15 14:41:50 +00:00',
      3,
      9.96,
      -73.99095917,
      40.74534225,
      -73.97360229,
      40.63248062,
      35.5,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 14:00:44 +00:00',
      '2015-01-15 14:04:55 +00:00',
      1,
      0.76,
      -73.96351624,
      40.77156067,
      -73.95298767,
      40.77246094,
      5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 14:00:44 +00:00',
      '2015-01-15 14:04:06 +00:00',
      1,
      0.38,
      -73.99138641,
      40.7701683,
      -73.98978424,
      40.77473831,
      4,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 14:00:45 +00:00',
      '2015-01-15 14:05:40 +00:00',
      2,
      0.63,
      -73.95426941,
      40.77827072,
      -73.95627594,
      40.78404617,
      5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 14:00:45 +00:00',
      '2015-01-15 14:39:09 +00:00',
      1,
      12.63,
      -73.78852844,
      40.64778519,
      -73.97788239,
      40.68964005,
      40,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 14:00:45 +00:00',
      '2015-01-15 14:10:24 +00:00',
      null,
      1.2,
      -73.9940033,
      40.7513504,
      -73.98135376,
      40.76112366,
      8.5,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 14:00:45 +00:00',
      '2015-01-15 14:16:31 +00:00',
      2,
      1.18,
      -74.00641632,
      40.73965454,
      -73.98991394,
      40.73839569,
      10.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 14:00:45 +00:00',
      '2015-01-15 14:47:12 +00:00',
      1,
      10.89,
      -74.00640869,
      40.73976517,
      -73.87119293,
      40.77421188,
      40.5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 14:00:46 +00:00',
      '2015-01-15 14:41:54 +00:00',
      6,
      10.39,
      -73.9930954,
      40.74725723,
      -73.86483002,
      40.77032089,
      38,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 14:00:46 +00:00',
      '2015-01-15 14:33:02 +00:00',
      5,
      2.96,
      -73.96273041,
      40.77416992,
      -73.99333191,
      40.7498703,
      20.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 14:00:46 +00:00',
      '2015-01-15 14:10:09 +00:00',
      1,
      1.18,
      -73.9825592,
      40.77320099,
      -73.96412659,
      40.76470947,
      8,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 14:00:46 +00:00',
      '2015-01-15 14:14:51 +00:00',
      1,
      1.62,
      -73.97618103,
      40.75120544,
      -73.9863739,
      40.7665863,
      10.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 14:00:47 +00:00',
      '2015-01-15 14:16:01 +00:00',
      null,
      1.56,
      -73.95927429,
      40.77437592,
      -73.97639465,
      40.76293945,
      10.5,
      true,
      'banana peel 2'
    ],
    [
      '2015-01-15 14:00:47 +00:00',
      '2015-01-15 14:23:36 +00:00',
      5,
      4.96,
      -73.97286224,
      40.74352264,
      -73.91412354,
      40.76496887,
      19,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 10:26:13 +00:00',
      '2015-01-15 10:30:49 +00:00',
      1,
      0.8,
      -73.97223663,
      40.74559402,
      -73.96466827,
      40.75601959,
      5.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 10:26:14 +00:00',
      '2015-01-15 10:42:16 +00:00',
      -1,
      1.9,
      -73.94948578,
      40.77207184,
      -73.97072601,
      40.75839233,
      12.5,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 10:26:14 +00:00',
      '2015-01-15 10:51:03 +00:00',
      1,
      2.6,
      -73.96176147,
      40.76427078,
      -73.98921967,
      40.74206543,
      16,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 10:26:14 +00:00',
      '2015-01-15 10:38:13 +00:00',
      1,
      0.8,
      -73.99343872,
      40.74200058,
      -73.98744965,
      40.75202179,
      8.5,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 10:26:14 +00:00',
      '2015-01-15 10:49:00 +00:00',
      null,
      6.8,
      -73.97297668,
      40.7611351,
      -74.00971222,
      40.70956421,
      23,
      true,
      'banana peel 2'
    ],
    [
      '2015-01-15 10:26:15 +00:00',
      '2015-01-15 10:34:40 +00:00',
      1,
      1.3,
      -73.94763947,
      40.77514267,
      -73.96069336,
      40.76080704,
      7.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 10:26:15 +00:00',
      '2015-01-15 10:35:33 +00:00',
      1,
      0.7,
      -73.98516846,
      40.75121307,
      -73.97951508,
      40.7618866,
      7.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 10:26:15 +00:00',
      '2015-01-15 10:33:37 +00:00',
      1,
      0.3,
      -73.98387146,
      40.74245453,
      -73.98730469,
      40.74406052,
      6,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 10:26:15 +00:00',
      '2015-01-15 10:36:03 +00:00',
      1,
      1.4,
      -73.96815491,
      40.76235962,
      -73.98164368,
      40.74707031,
      8,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 10:26:16 +00:00',
      '2015-01-15 10:32:04 +00:00',
      1,
      1.2,
      -74.00554657,
      40.72677612,
      -74.01348114,
      40.71365738,
      6.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 10:26:16 +00:00',
      '2015-01-15 11:08:10 +00:00',
      2,
      12.6,
      -73.86273956,
      40.7688446,
      -73.98956299,
      40.75883865,
      40,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 10:26:16 +00:00',
      '2015-01-15 10:26:42 +00:00',
      2,
      0,
      -73.98765564,
      40.74364471,
      -73.98748779,
      40.7434845,
      60,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 10:26:17 +00:00',
      '2015-01-15 10:27:28 +00:00',
      1,
      0,
      -73.94652557,
      40.7449913,
      -73.94652557,
      40.7449913,
      3,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 09:46:59 +00:00',
      '2015-01-15 09:57:44 +00:00',
      1,
      3.1,
      -74.01138306,
      40.71348953,
      -73.99554443,
      40.74922943,
      11.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 09:46:59 +00:00',
      '2015-01-15 09:56:44 +00:00',
      1,
      0.8,
      -73.99701691,
      40.74232101,
      -73.98770142,
      40.73540497,
      7.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 09:46:59 +00:00',
      '2015-01-15 10:27:34 +00:00',
      1,
      9.5,
      -73.87297058,
      40.77403259,
      -74.00010681,
      40.71933746,
      34,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 09:47:00 +00:00',
      '2015-01-15 09:50:43 +00:00',
      1,
      0.4,
      -74.00221252,
      40.72949219,
      -73.9969101,
      40.72514343,
      4.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 09:47:02 +00:00',
      '2015-01-15 10:01:48 +00:00',
      1,
      1.1,
      -73.96573639,
      40.7954216,
      -73.95262909,
      40.78934479,
      10,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 09:47:02 +00:00',
      '2015-01-15 10:14:12 +00:00',
      2,
      2.2,
      -74.00695801,
      40.73000336,
      -73.98561859,
      40.7528801,
      16.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 09:47:03 +00:00',
      '2015-01-15 09:57:10 +00:00',
      1,
      0.7,
      -73.97551727,
      40.75428009,
      -73.97853088,
      40.76155853,
      7.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 09:47:03 +00:00',
      '2015-01-15 09:53:04 +00:00',
      3,
      0.8,
      -73.94737244,
      40.77603912,
      -73.95754242,
      40.77267075,
      6,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 09:47:03 +00:00',
      '2015-01-15 09:51:15 +00:00',
      1,
      1.1,
      -73.96231079,
      40.75884628,
      -73.95182037,
      40.77346039,
      5.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 09:47:04 +00:00',
      '2015-01-15 10:02:36 +00:00',
      1,
      1.4,
      -73.98874664,
      40.75502777,
      -73.99000549,
      40.73872375,
      10.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 09:47:04 +00:00',
      '2015-01-15 10:00:56 +00:00',
      2,
      1.6,
      -73.98256683,
      40.73551559,
      -73.9910965,
      40.74888992,
      10,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 09:47:05 +00:00',
      '2015-01-15 09:52:25 +00:00',
      1,
      0.6,
      -73.99432373,
      40.75033188,
      -74.00239563,
      40.74866867,
      5.5,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 09:47:05 +00:00',
      '2015-01-15 09:53:18 +00:00',
      1,
      0.7,
      -73.96313477,
      40.77178955,
      -73.95404053,
      40.76702499,
      5.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 09:47:05 +00:00',
      '2015-01-15 09:48:54 +00:00',
      1,
      0.5,
      -73.96200562,
      40.75959015,
      -73.95683289,
      40.76490021,
      3.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 09:47:05 +00:00',
      '2015-01-15 09:51:23 +00:00',
      1,
      0.9,
      -73.97065735,
      40.78341293,
      -73.9588623,
      40.78094482,
      5.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 09:47:06 +00:00',
      '2015-01-15 09:55:23 +00:00',
      1,
      1.6,
      -73.96646881,
      40.79238129,
      -73.95868683,
      40.77577209,
      8,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 09:47:06 +00:00',
      '2015-01-15 10:01:35 +00:00',
      2,
      6.9,
      -73.98991394,
      40.75692368,
      -73.94661713,
      40.83629227,
      21,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 09:47:07 +00:00',
      '2015-01-15 09:55:25 +00:00',
      1,
      0.7,
      -73.9852066,
      40.74163055,
      -73.97882843,
      40.75057602,
      7,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 09:47:07 +00:00',
      '2015-01-15 10:08:50 +00:00',
      1,
      2.9,
      -73.97415161,
      40.78395081,
      -73.97834778,
      40.75157547,
      15,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 09:47:07 +00:00',
      '2015-01-15 09:56:05 +00:00',
      1,
      1.6,
      -73.97924042,
      40.77156448,
      -73.96215057,
      40.77807236,
      8.5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 10:26:10 +00:00',
      '2015-01-15 10:42:12 +00:00',
      1,
      1.5,
      -73.99133301,
      40.74978256,
      -73.97821045,
      40.73733521,
      11,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 10:26:10 +00:00',
      '2015-01-15 10:53:36 +00:00',
      1,
      3.7,
      -73.99958801,
      40.72176361,
      -73.96715546,
      40.75602341,
      18.5,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 10:26:12 +00:00',
      '2015-01-15 10:46:58 +00:00',
      1,
      1.8,
      -73.97556305,
      40.75548553,
      -73.99723816,
      40.75146484,
      13.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 10:26:12 +00:00',
      '2015-01-15 10:50:20 +00:00',
      1,
      1.4,
      -73.97470093,
      40.74204636,
      -73.97841644,
      40.75669479,
      15.5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 10:26:12 +00:00',
      '2015-01-15 10:39:05 +00:00',
      1,
      1.4,
      -73.9725647,
      40.75384521,
      -73.98822021,
      40.74080658,
      9.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 10:26:12 +00:00',
      '2015-01-15 10:43:52 +00:00',
      2,
      2.6,
      -73.9773941,
      40.78989792,
      -73.9559021,
      40.7661171,
      13,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 10:26:12 +00:00',
      '2015-01-15 10:32:31 +00:00',
      1,
      0.8,
      -73.95783997,
      40.7794075,
      -73.95165253,
      40.77099991,
      6,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 10:26:13 +00:00',
      '2015-01-15 10:35:01 +00:00',
      1,
      0.5,
      -73.98423767,
      40.7456665,
      -73.97926331,
      40.74409866,
      7,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 10:56:46 +00:00',
      '2015-01-15 11:06:13 +00:00',
      1,
      1.6,
      -73.98516846,
      40.76850128,
      -73.98157501,
      40.75225067,
      7.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 10:56:46 +00:00',
      '2015-01-15 11:09:23 +00:00',
      1,
      2,
      -73.98225403,
      40.76245117,
      -73.99003601,
      40.73810959,
      10,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 10:56:47 +00:00',
      '2015-01-15 11:23:18 +00:00',
      1,
      5.4,
      -73.97812653,
      40.75257492,
      -74.00234222,
      40.7152977,
      22,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 10:56:48 +00:00',
      '2015-01-15 11:07:26 +00:00',
      1,
      1.5,
      -74.00622559,
      40.73387146,
      -73.99993134,
      40.75349426,
      9,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 10:56:48 +00:00',
      '2015-01-15 11:08:55 +00:00',
      1,
      1.5,
      -73.97122955,
      40.75518417,
      -73.95401001,
      40.76623917,
      9.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 10:56:48 +00:00',
      '2015-01-15 11:10:30 +00:00',
      1,
      2.2,
      -73.97627258,
      40.75893402,
      -73.98604584,
      40.73451614,
      11,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 10:56:48 +00:00',
      '2015-01-15 11:06:10 +00:00',
      1,
      1.4,
      -73.97628021,
      40.77590942,
      -73.96232605,
      40.76773834,
      8.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 10:56:49 +00:00',
      '2015-01-15 11:03:29 +00:00',
      1,
      0.7,
      -73.98015594,
      40.76077652,
      -73.97042084,
      40.76482773,
      6,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 10:56:49 +00:00',
      '2015-01-15 11:05:22 +00:00',
      1,
      0.5,
      -73.98032379,
      40.74335861,
      -73.97641754,
      40.73955154,
      6.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 10:56:49 +00:00',
      '2015-01-15 11:04:05 +00:00',
      1,
      0.5,
      -73.97009277,
      40.7521019,
      -73.97557068,
      40.7491951,
      6,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 10:56:49 +00:00',
      '2015-01-15 11:12:20 +00:00',
      1,
      1.5,
      -73.98545837,
      40.75137329,
      -74.00403595,
      40.75012589,
      11,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 10:56:49 +00:00',
      '2015-01-15 11:25:21 +00:00',
      1,
      8.7,
      -73.86603546,
      40.77074432,
      -73.98206329,
      40.74584198,
      29,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 10:56:50 +00:00',
      '2015-01-15 11:31:54 +00:00',
      1,
      12.5,
      -73.99441528,
      40.72490692,
      -73.94686127,
      40.61621094,
      38,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 10:56:50 +00:00',
      '2015-01-15 11:16:04 +00:00',
      1,
      0,
      -73.99421692,
      40.73490906,
      -73.97660828,
      40.75004578,
      12,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 10:56:50 +00:00',
      '2015-01-15 11:03:51 +00:00',
      1,
      1.3,
      -74.01493835,
      40.71023178,
      -74.0075531,
      40.72661591,
      7,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 10:56:50 +00:00',
      '2015-01-15 11:21:30 +00:00',
      1,
      6,
      -73.99806976,
      40.73566437,
      -73.96785736,
      40.80531311,
      22.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 10:56:50 +00:00',
      '2015-01-15 11:03:30 +00:00',
      1,
      1.3,
      -73.98352051,
      40.76331329,
      -73.99245453,
      40.74995804,
      6,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 10:56:52 +00:00',
      '2015-01-15 11:05:46 +00:00',
      1,
      1.2,
      -74.00131989,
      40.7518959,
      -73.98478699,
      40.75503922,
      7.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 10:56:52 +00:00',
      '2015-01-15 11:11:58 +00:00',
      1,
      3.6,
      -74.01648712,
      40.71627426,
      -73.99113464,
      40.75481415,
      13.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 10:56:52 +00:00',
      '2015-01-15 11:12:56 +00:00',
      1,
      1.6,
      -73.9691925,
      40.76200104,
      -73.98760986,
      40.74968338,
      11,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 10:56:53 +00:00',
      '2015-01-15 10:59:35 +00:00',
      1,
      0.4,
      -73.9547348,
      40.76564026,
      -73.95345306,
      40.77137756,
      4,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 11:35:15 +00:00',
      '2015-01-15 11:53:23 +00:00',
      1,
      2.2,
      -73.9984436,
      40.71989441,
      -74.00462341,
      40.74206543,
      12.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 11:35:15 +00:00',
      '2015-01-15 11:43:13 +00:00',
      1,
      1.2,
      -73.9718399,
      40.79421616,
      -73.95295715,
      40.78664398,
      7.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 11:35:16 +00:00',
      '2015-01-15 12:01:53 +00:00',
      1,
      6.8,
      -73.95982361,
      40.77128983,
      -74.00769043,
      40.71765518,
      24.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 11:35:17 +00:00',
      '2015-01-15 11:49:54 +00:00',
      1,
      1.9,
      -73.9616394,
      40.77980804,
      -73.98139954,
      40.75747299,
      10.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 11:35:18 +00:00',
      '2015-01-15 11:51:31 +00:00',
      2,
      0.8,
      -73.96839905,
      40.76218033,
      -73.98125458,
      40.76370239,
      10.5,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 11:35:19 +00:00',
      '2015-01-15 11:46:14 +00:00',
      1,
      1.4,
      -73.96898651,
      40.79833221,
      -73.97193146,
      40.78203964,
      9,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 11:35:20 +00:00',
      '2015-01-15 11:43:38 +00:00',
      1,
      1.7,
      -73.98987579,
      40.76201248,
      -73.98152924,
      40.78125763,
      8,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 11:35:20 +00:00',
      '2015-01-15 11:47:17 +00:00',
      1,
      1.4,
      -73.98216248,
      40.77657318,
      -73.97359467,
      40.76300049,
      9.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 11:35:21 +00:00',
      '2015-01-15 11:35:28 +00:00',
      1,
      0,
      -73.9733963,
      40.76347733,
      -73.9733963,
      40.76347733,
      2.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 11:35:22 +00:00',
      '2015-01-15 11:46:32 +00:00',
      1,
      1.1,
      -73.98245239,
      40.7314949,
      -73.97905731,
      40.74420929,
      8.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 11:35:23 +00:00',
      '2015-01-15 11:44:32 +00:00',
      1,
      1.2,
      -73.94425964,
      40.7760582,
      -73.96222687,
      40.77615738,
      8,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 11:35:24 +00:00',
      '2015-01-15 11:58:40 +00:00',
      1,
      2.1,
      -74.00631714,
      40.73365784,
      -74.00494385,
      40.71043015,
      15,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 11:35:25 +00:00',
      '2015-01-15 11:51:24 +00:00',
      1,
      1.9,
      -73.98616791,
      40.72227859,
      -73.99510193,
      40.73960114,
      12,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 11:35:25 +00:00',
      '2015-01-15 11:51:06 +00:00',
      1,
      2,
      -73.96168518,
      40.75761414,
      -73.98378754,
      40.74235153,
      11,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 11:35:25 +00:00',
      '2015-01-15 12:01:33 +00:00',
      1,
      3.5,
      -73.9727478,
      40.74853134,
      -74.00801849,
      40.72272873,
      18,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 11:35:25 +00:00',
      '2015-01-15 11:40:23 +00:00',
      1,
      0.6,
      -73.96208954,
      40.76380157,
      -73.96765137,
      40.75511551,
      5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 11:35:25 +00:00',
      '2015-01-15 11:56:42 +00:00',
      1,
      1.9,
      -73.9851532,
      40.7371788,
      -73.96670532,
      40.76086426,
      14,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 11:35:26 +00:00',
      '2015-01-15 11:51:09 +00:00',
      2,
      2.3,
      -73.97872925,
      40.75224304,
      -73.96221161,
      40.77930069,
      12,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 11:35:26 +00:00',
      '2015-01-15 11:44:50 +00:00',
      1,
      1.3,
      -73.95684814,
      40.78347778,
      -73.95326996,
      40.79862976,
      7.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 11:35:26 +00:00',
      '2015-01-15 11:48:16 +00:00',
      1,
      1.8,
      -73.95348358,
      40.76652908,
      -73.97425842,
      40.75358963,
      10,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 11:35:27 +00:00',
      '2015-01-15 11:43:45 +00:00',
      2,
      0.9,
      -73.98709869,
      40.7389946,
      -73.97855377,
      40.75112534,
      7,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 11:49:53 +00:00',
      '2015-01-15 12:02:35 +00:00',
      1,
      1.3,
      -73.98623657,
      40.74385834,
      -73.97293854,
      40.75786209,
      9,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 11:49:53 +00:00',
      '2015-01-15 12:06:10 +00:00',
      1,
      6.3,
      -73.96685791,
      40.76138687,
      -73.87236786,
      40.77438354,
      20,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 11:49:53 +00:00',
      '2015-01-15 11:56:36 +00:00',
      1,
      0.4,
      -73.96569824,
      40.75917053,
      -73.97373962,
      40.7628479,
      6,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 11:49:54 +00:00',
      '2015-01-15 11:59:59 +00:00',
      1,
      0.9,
      -74.00165558,
      40.74570465,
      -73.98720551,
      40.73950577,
      8,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 11:49:54 +00:00',
      '2015-01-15 11:50:33 +00:00',
      1,
      0,
      -73.94851685,
      40.74454117,
      -73.94844818,
      40.74446869,
      20,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 11:49:54 +00:00',
      '2015-01-15 12:20:30 +00:00',
      1,
      9.9,
      -73.95471954,
      40.6891098,
      -73.87099457,
      40.77423859,
      31,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 11:49:54 +00:00',
      '2015-01-15 11:51:35 +00:00',
      1,
      0.3,
      -73.97939301,
      40.78484344,
      -73.98139191,
      40.78102112,
      3.5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 11:49:55 +00:00',
      '2015-01-15 12:15:37 +00:00',
      1,
      2.1,
      -73.96233368,
      40.7553978,
      -73.9940567,
      40.75914383,
      16,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 11:49:55 +00:00',
      '2015-01-15 12:23:44 +00:00',
      2,
      2.2,
      -73.9991684,
      40.72670746,
      -73.98468781,
      40.75434113,
      20,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 11:49:55 +00:00',
      '2015-01-15 12:01:31 +00:00',
      1,
      1.9,
      -73.99319458,
      40.74750137,
      -73.99531555,
      40.72745132,
      9,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 11:49:55 +00:00',
      '2015-01-15 11:57:33 +00:00',
      1,
      1.3,
      -73.98823547,
      40.76163101,
      -73.97983551,
      40.77527237,
      7,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 11:49:55 +00:00',
      '2015-01-15 12:02:53 +00:00',
      1,
      1.5,
      -73.98677063,
      40.74547958,
      -73.97886658,
      40.76271439,
      9.5,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 11:49:55 +00:00',
      '2015-01-15 11:54:40 +00:00',
      1,
      0.8,
      -73.99420166,
      40.75626373,
      -73.99887848,
      40.74616241,
      5,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 11:49:56 +00:00',
      '2015-01-15 12:38:59 +00:00',
      3,
      17.4,
      -73.9848175,
      40.75334549,
      -73.78993988,
      40.6469841,
      52,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 11:49:56 +00:00',
      '2015-01-15 11:56:08 +00:00',
      1,
      0.9,
      -73.98517609,
      40.75651169,
      -73.98495483,
      40.74806595,
      6,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 11:49:56 +00:00',
      '2015-01-15 12:02:54 +00:00',
      1,
      1.4,
      -73.98865509,
      40.7688942,
      -73.97301483,
      40.78256226,
      10,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 11:49:56 +00:00',
      '2015-01-15 12:01:01 +00:00',
      1,
      1.8,
      -73.96639252,
      40.78882599,
      -73.98210907,
      40.76841354,
      9.5,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 11:49:57 +00:00',
      '2015-01-15 12:03:47 +00:00',
      4,
      1.9,
      -73.9731369,
      40.75410843,
      -73.98535156,
      40.73196411,
      10.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 11:49:57 +00:00',
      '2015-01-15 12:03:11 +00:00',
      1,
      0.9,
      -73.97318268,
      40.75216675,
      -73.98213196,
      40.75175858,
      9.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 11:49:57 +00:00',
      '2015-01-15 11:58:02 +00:00',
      1,
      0.9,
      -73.99911499,
      40.72656631,
      -73.99121094,
      40.71820831,
      7,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 11:49:57 +00:00',
      '2015-01-15 12:14:42 +00:00',
      1,
      2.8,
      -73.99176788,
      40.74915695,
      -73.95938873,
      40.76327515,
      16.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 12:09:15 +00:00',
      '2015-01-15 12:24:25 +00:00',
      1,
      1.5,
      -73.98931885,
      40.73603058,
      -73.98506165,
      40.75305939,
      10.5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 12:09:15 +00:00',
      '2015-01-15 12:46:14 +00:00',
      1,
      17,
      -73.98258209,
      40.73946762,
      -73.77700043,
      40.6446991,
      52,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 12:09:16 +00:00',
      '2015-01-15 12:22:54 +00:00',
      1,
      3.5,
      -74.0118866,
      40.71377945,
      -74.00259399,
      40.76077271,
      13.5,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 12:09:16 +00:00',
      '2015-01-15 12:26:07 +00:00',
      1,
      0.9,
      -73.96848297,
      40.76449585,
      -73.97989655,
      40.75763321,
      11,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 12:09:16 +00:00',
      '2015-01-15 12:21:53 +00:00',
      2,
      2.1,
      -73.97838593,
      40.75712967,
      -73.98999023,
      40.73477173,
      10.5,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 12:09:16 +00:00',
      '2015-01-15 12:19:48 +00:00',
      1,
      1.4,
      -73.97607422,
      40.75993729,
      -73.96143341,
      40.77157211,
      8.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 12:09:16 +00:00',
      '2015-01-15 12:14:50 +00:00',
      1,
      0.7,
      -73.99591827,
      40.75410461,
      -73.98538208,
      40.74870682,
      5.5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 12:09:16 +00:00',
      '2015-01-15 12:22:32 +00:00',
      1,
      2.6,
      -73.98551941,
      40.76311111,
      -73.95552063,
      40.7767334,
      11.5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 12:09:16 +00:00',
      '2015-01-15 12:26:30 +00:00',
      1,
      1.5,
      -74.00605774,
      40.74533844,
      -73.9868927,
      40.75108337,
      12,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 12:09:17 +00:00',
      '2015-01-15 12:21:25 +00:00',
      1,
      1.5,
      -73.97014618,
      40.78909683,
      -73.95428467,
      40.78998566,
      9.5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 12:09:18 +00:00',
      '2015-01-15 12:27:32 +00:00',
      1,
      2.9,
      -73.9768219,
      40.75079346,
      -74.00571442,
      40.7264595,
      13.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 12:09:18 +00:00',
      '2015-01-15 12:34:06 +00:00',
      1,
      4.5,
      -73.94947815,
      40.78125381,
      -73.98471832,
      40.73619461,
      19,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 12:09:19 +00:00',
      '2015-01-15 12:16:46 +00:00',
      1,
      1.2,
      -73.99941254,
      40.72211075,
      -73.99222565,
      40.73061752,
      7,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 12:09:19 +00:00',
      '2015-01-15 12:18:59 +00:00',
      1,
      1.5,
      -74.00551605,
      40.73760223,
      -74.00695038,
      40.71791077,
      8,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 12:09:19 +00:00',
      '2015-01-15 12:09:49 +00:00',
      1,
      0,
      -73.97665405,
      40.74756622,
      -73.97657776,
      40.74789429,
      52,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 12:09:19 +00:00',
      '2015-01-15 12:19:40 +00:00',
      1,
      1,
      -73.96508026,
      40.77230072,
      -73.96895599,
      40.76291275,
      8,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 12:09:19 +00:00',
      '2015-01-15 12:21:35 +00:00',
      1,
      0.5,
      -73.97304535,
      40.75561142,
      -73.97867584,
      40.75485992,
      8.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 12:09:20 +00:00',
      '2015-01-15 12:16:18 +00:00',
      1,
      0.8,
      -73.96009827,
      40.76205063,
      -73.95944214,
      40.77100372,
      6.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 12:09:20 +00:00',
      '2015-01-15 12:11:19 +00:00',
      1,
      0.3,
      -73.98959351,
      40.76280975,
      -73.99365997,
      40.75977707,
      3.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 12:09:21 +00:00',
      '2015-01-15 12:26:54 +00:00',
      1,
      1.4,
      -73.98150635,
      40.74670792,
      -73.97824097,
      40.76279449,
      12,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 12:09:21 +00:00',
      '2015-01-15 12:35:03 +00:00',
      1,
      5.5,
      -74.01249695,
      40.70261383,
      -73.98406219,
      40.76308823,
      21.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 13:08:41 +00:00',
      '2015-01-15 13:26:26 +00:00',
      1,
      1.8,
      -73.99486542,
      40.72507477,
      -73.99129486,
      40.74486542,
      12.5,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 13:08:42 +00:00',
      '2015-01-15 13:19:14 +00:00',
      1,
      1.3,
      -73.97502136,
      40.75313187,
      -73.9878006,
      40.73916626,
      8.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 13:08:43 +00:00',
      '2015-01-15 13:17:41 +00:00',
      1,
      0.6,
      -73.98260498,
      40.73439026,
      -73.97531128,
      40.74148941,
      7,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 13:08:43 +00:00',
      '2015-01-15 13:19:05 +00:00',
      2,
      1.3,
      -73.98284912,
      40.7354126,
      -73.99924469,
      40.7266922,
      8.5,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 13:08:43 +00:00',
      '2015-01-15 13:34:05 +00:00',
      1,
      3.6,
      -73.95719147,
      40.78299713,
      -73.99330902,
      40.74977875,
      17.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 13:08:44 +00:00',
      '2015-01-15 13:14:13 +00:00',
      1,
      0.5,
      -73.98175812,
      40.75990677,
      -73.97349548,
      40.75848007,
      5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 13:08:44 +00:00',
      '2015-01-15 13:17:26 +00:00',
      1,
      1.1,
      -73.95582581,
      40.78220749,
      -73.95806885,
      40.76962662,
      7.5,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 13:08:45 +00:00',
      '2015-01-15 13:19:40 +00:00',
      1,
      1.9,
      -73.96497345,
      40.75543213,
      -73.96150208,
      40.77624893,
      9,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 13:08:45 +00:00',
      '2015-01-15 13:23:14 +00:00',
      1,
      2.4,
      -73.95355988,
      40.77692032,
      -73.98401642,
      40.77029419,
      11.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 13:08:45 +00:00',
      '2015-01-15 13:17:58 +00:00',
      1,
      0.3,
      -73.97407532,
      40.76476288,
      -73.96971893,
      40.76197052,
      7,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 13:08:46 +00:00',
      '2015-01-15 13:26:23 +00:00',
      1,
      1.4,
      -73.99007416,
      40.73517609,
      -73.99027252,
      40.74991226,
      11.5,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 13:08:46 +00:00',
      '2015-01-15 13:13:56 +00:00',
      1,
      0.9,
      -73.96412659,
      40.79253387,
      -73.95281982,
      40.78904343,
      5.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 13:08:46 +00:00',
      '2015-01-15 13:15:29 +00:00',
      3,
      1.2,
      -73.97790527,
      40.75739288,
      -73.98935699,
      40.74221802,
      7,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 13:08:47 +00:00',
      '2015-01-15 13:26:21 +00:00',
      1,
      1.7,
      -73.98762512,
      40.74157333,
      -73.9746933,
      40.76345444,
      12,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 13:08:47 +00:00',
      '2015-01-15 13:21:41 +00:00',
      1,
      0.6,
      -73.98404694,
      40.7493248,
      -73.98500824,
      40.75600052,
      9,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 13:08:48 +00:00',
      '2015-01-15 13:23:10 +00:00',
      1,
      1.8,
      -73.97254944,
      40.76478958,
      -73.99060059,
      40.74874496,
      10.5,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 13:08:49 +00:00',
      '2015-01-15 13:13:00 +00:00',
      1,
      0.9,
      -73.96455383,
      40.77020264,
      -73.95604706,
      40.78163147,
      5.5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 13:08:49 +00:00',
      '2015-01-15 13:23:34 +00:00',
      1,
      0.9,
      -73.98060608,
      40.73400497,
      -73.98609924,
      40.7435112,
      10,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 13:08:49 +00:00',
      '2015-01-15 13:30:58 +00:00',
      4,
      2.5,
      -73.97557068,
      40.76527786,
      -73.94625854,
      40.7804451,
      15.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 13:08:49 +00:00',
      '2015-01-15 13:13:42 +00:00',
      1,
      1.2,
      -73.96572876,
      40.79036331,
      -73.97844696,
      40.77741241,
      6.5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 13:08:49 +00:00',
      '2015-01-15 13:29:01 +00:00',
      1,
      1.6,
      -73.96913147,
      40.76098633,
      -73.98926544,
      40.75290298,
      13,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 13:08:50 +00:00',
      '2015-01-15 13:32:26 +00:00',
      2,
      6.1,
      -74.01412964,
      40.71213531,
      -73.97323608,
      40.78503036,
      22,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 13:28:31 +00:00',
      '2015-01-15 13:58:40 +00:00',
      1,
      3.1,
      -73.99403381,
      40.75093079,
      -73.99698639,
      40.71781158,
      19.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 13:28:32 +00:00',
      '2015-01-15 13:44:36 +00:00',
      1,
      3.2,
      -73.94548798,
      40.77409363,
      -73.98342133,
      40.74536514,
      14.5,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 13:28:32 +00:00',
      '2015-01-15 13:32:56 +00:00',
      1,
      0.6,
      -73.96230316,
      40.76759338,
      -73.96643829,
      40.76444244,
      5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 13:28:33 +00:00',
      '2015-01-15 13:32:51 +00:00',
      1,
      0.6,
      -73.96742249,
      40.7690239,
      -73.96128082,
      40.77759552,
      5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 13:28:33 +00:00',
      '2015-01-15 13:49:49 +00:00',
      1,
      1.1,
      -73.97927094,
      40.76162338,
      -73.97733307,
      40.75179291,
      13,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 13:28:33 +00:00',
      '2015-01-15 13:44:36 +00:00',
      1,
      1.9,
      -73.98165131,
      40.77998734,
      -73.99479675,
      40.75537109,
      11.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 13:28:34 +00:00',
      '2015-01-15 13:30:29 +00:00',
      1,
      0.4,
      -73.9751358,
      40.77751541,
      -73.98141479,
      40.77855301,
      3.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 13:28:34 +00:00',
      '2015-01-15 13:46:03 +00:00',
      1,
      0.6,
      -73.9865036,
      40.75201797,
      -73.98056793,
      40.76026917,
      11,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 13:28:34 +00:00',
      '2015-01-15 13:53:30 +00:00',
      1,
      9.1,
      -73.96624756,
      40.76560974,
      -73.87232208,
      40.77435303,
      29,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 13:28:34 +00:00',
      '2015-01-15 13:43:58 +00:00',
      1,
      1.7,
      -73.97924042,
      40.76350784,
      -73.98809814,
      40.74477005,
      11,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 13:28:35 +00:00',
      '2015-01-15 13:55:10 +00:00',
      1,
      2.6,
      -74.00570679,
      40.74041748,
      -73.97388458,
      40.75088882,
      17,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 13:28:35 +00:00',
      '2015-01-15 13:46:07 +00:00',
      1,
      4.9,
      -74.00881195,
      40.70446777,
      -73.98458862,
      40.74863434,
      18.5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 13:28:35 +00:00',
      '2015-01-15 13:42:22 +00:00',
      1,
      1.3,
      -73.99788666,
      40.74094391,
      -73.98456573,
      40.74730682,
      10,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 13:28:36 +00:00',
      '2015-01-15 13:35:23 +00:00',
      3,
      1.1,
      -73.99003601,
      40.73796463,
      -73.99784851,
      40.72401047,
      6.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 14:48:02 +00:00',
      '2015-01-15 15:02:15 +00:00',
      1,
      1.7,
      -73.96941376,
      40.76636124,
      -73.95368958,
      40.78794479,
      10.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 14:48:02 +00:00',
      '2015-01-15 15:35:10 +00:00',
      1,
      18.7,
      -73.78253937,
      40.64458466,
      -73.99058533,
      40.72745132,
      52,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 14:48:02 +00:00',
      '2015-01-15 14:58:13 +00:00',
      1,
      1.7,
      -73.97823334,
      40.7544632,
      -73.98209381,
      40.77376556,
      9,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 14:48:02 +00:00',
      '2015-01-15 16:02:16 +00:00',
      1,
      17.9,
      -73.9863205,
      40.72613144,
      -73.78303528,
      40.64387894,
      52,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 14:48:02 +00:00',
      '2015-01-15 14:52:03 +00:00',
      4,
      0.7,
      -73.97944641,
      40.77158737,
      -73.98216248,
      40.76358795,
      4.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 14:48:03 +00:00',
      '2015-01-15 14:54:40 +00:00',
      1,
      0.7,
      -73.98408508,
      40.73751831,
      -73.9903183,
      40.73723984,
      6,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 14:48:03 +00:00',
      '2015-01-15 15:07:23 +00:00',
      1,
      2.1,
      -74.00341034,
      40.72686768,
      -73.98469543,
      40.75408173,
      13,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 14:48:03 +00:00',
      '2015-01-15 15:28:40 +00:00',
      2,
      11.6,
      -73.77835846,
      40.64676285,
      -73.95575714,
      40.65594101,
      39,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 14:48:03 +00:00',
      '2015-01-15 14:50:48 +00:00',
      1,
      0.7,
      -73.96367645,
      40.77734756,
      -73.97085571,
      40.78310394,
      4.5,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 14:48:04 +00:00',
      '2015-01-15 15:12:15 +00:00',
      2,
      2.7,
      -73.96225739,
      40.77906799,
      -73.99147034,
      40.75802994,
      16,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 14:48:04 +00:00',
      '2015-01-15 14:52:41 +00:00',
      1,
      0.6,
      -73.97543335,
      40.75215912,
      -73.96953583,
      40.75200653,
      5,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 14:48:04 +00:00',
      '2015-01-15 14:54:52 +00:00',
      1,
      0.8,
      -73.97546387,
      40.75792694,
      -73.97644043,
      40.75066376,
      6,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 14:48:04 +00:00',
      '2015-01-15 14:54:11 +00:00',
      1,
      0.7,
      -73.98100281,
      40.75943375,
      -73.99040222,
      40.75676727,
      5.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 14:48:05 +00:00',
      '2015-01-15 14:52:33 +00:00',
      1,
      0.8,
      -73.96006012,
      40.76252747,
      -73.95191193,
      40.77336502,
      5.5,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 14:48:05 +00:00',
      '2015-01-15 15:02:12 +00:00',
      1,
      2.6,
      -73.95397949,
      40.76646423,
      -73.98020935,
      40.77532959,
      11.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 14:48:05 +00:00',
      '2015-01-15 15:17:12 +00:00',
      1,
      10.2,
      -73.87229156,
      40.77449799,
      -73.9723587,
      40.76523972,
      32.5,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 14:48:06 +00:00',
      '2015-01-15 14:58:55 +00:00',
      1,
      1.3,
      -73.99183655,
      40.7434082,
      -73.97814178,
      40.75121689,
      8.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 14:48:06 +00:00',
      '2015-01-15 15:03:19 +00:00',
      1,
      2,
      -73.99678802,
      40.76287842,
      -73.98536682,
      40.7436142,
      11.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 14:48:06 +00:00',
      '2015-01-15 14:56:09 +00:00',
      1,
      0.8,
      -73.97661591,
      40.78069687,
      -73.98725128,
      40.77615738,
      6.5,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 13:28:37 +00:00',
      '2015-01-15 13:39:20 +00:00',
      1,
      1.3,
      -73.96536255,
      40.76590729,
      -73.95040131,
      40.77541351,
      8.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 13:28:37 +00:00',
      '2015-01-15 13:37:49 +00:00',
      1,
      2.9,
      -73.95761871,
      40.76847076,
      -73.97514343,
      40.73571014,
      11.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 13:28:37 +00:00',
      '2015-01-15 13:52:56 +00:00',
      2,
      2.4,
      -73.99796295,
      40.75651932,
      -73.9693985,
      40.76195526,
      16,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 13:28:37 +00:00',
      '2015-01-15 13:57:15 +00:00',
      1,
      3.3,
      -74.01082611,
      40.70900726,
      -73.98595428,
      40.75083542,
      19,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 13:28:37 +00:00',
      '2015-01-15 13:33:57 +00:00',
      1,
      0.5,
      -73.97025299,
      40.75899887,
      -73.97548676,
      40.75217438,
      5,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 13:28:38 +00:00',
      '2015-01-15 14:13:39 +00:00',
      1,
      10.4,
      -73.98912048,
      40.75639343,
      -73.87306213,
      40.77438354,
      38.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 11:08:27 +00:00',
      '2015-01-15 11:13:04 +00:00',
      1,
      0.57,
      -73.99191284,
      40.73366165,
      -73.99928284,
      40.73590088,
      5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 11:08:27 +00:00',
      '2015-01-15 11:17:22 +00:00',
      5,
      1.36,
      -73.97545624,
      40.78210831,
      -73.98251343,
      40.76395035,
      8,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 15:47:38 +00:00',
      '2015-01-15 16:13:56 +00:00',
      2,
      3.4,
      -73.98490143,
      40.76909256,
      -73.95078278,
      40.78242874,
      18.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 15:47:38 +00:00',
      '2015-01-15 16:11:28 +00:00',
      1,
      4.9,
      -73.98446655,
      40.75934601,
      -73.94443512,
      40.8130722,
      19.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 15:47:38 +00:00',
      '2015-01-15 15:56:08 +00:00',
      1,
      1.1,
      -73.95365906,
      40.76678848,
      -73.9686203,
      40.76480484,
      7.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 15:47:39 +00:00',
      '2015-01-15 16:01:53 +00:00',
      4,
      1.6,
      -74.00598907,
      40.74010849,
      -74.00376129,
      40.7591362,
      10.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 15:47:39 +00:00',
      '2015-01-15 16:06:25 +00:00',
      1,
      2.5,
      -73.98991394,
      40.7408638,
      -73.96156311,
      40.75641632,
      13.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 15:47:40 +00:00',
      '2015-01-15 15:54:36 +00:00',
      1,
      0.6,
      -73.9804306,
      40.76034927,
      -73.97067261,
      40.75859451,
      6,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 15:47:40 +00:00',
      '2015-01-15 15:57:34 +00:00',
      1,
      1.2,
      -73.98097992,
      40.73818588,
      -73.98110962,
      40.75061417,
      8,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 15:47:40 +00:00',
      '2015-01-15 15:56:19 +00:00',
      1,
      1.4,
      -73.98873138,
      40.75407028,
      -74.00460052,
      40.73969269,
      7.5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 15:47:41 +00:00',
      '2015-01-15 16:00:53 +00:00',
      1,
      1.4,
      -73.9916153,
      40.75009918,
      -73.99889374,
      40.7610817,
      9.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 15:47:41 +00:00',
      '2015-01-15 15:57:32 +00:00',
      1,
      1.3,
      -73.9928894,
      40.75384521,
      -73.97768402,
      40.76447296,
      8,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 15:47:41 +00:00',
      '2015-01-15 16:02:12 +00:00',
      2,
      2.1,
      -73.987854,
      40.75493622,
      -73.99325562,
      40.73668671,
      11,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 15:47:41 +00:00',
      '2015-01-15 17:08:39 +00:00',
      1,
      8.8,
      -73.98908997,
      40.75322723,
      -73.8707962,
      40.77392578,
      50,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 15:47:41 +00:00',
      '2015-01-15 15:54:06 +00:00',
      1,
      1.5,
      -73.98497009,
      40.76370239,
      -73.97200775,
      40.78221512,
      7,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 15:47:42 +00:00',
      '2015-01-15 16:17:48 +00:00',
      1,
      9.2,
      -73.8709259,
      40.77367401,
      -73.95968628,
      40.7660408,
      31.5,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 15:47:42 +00:00',
      '2015-01-15 15:53:42 +00:00',
      2,
      0.7,
      -73.95475006,
      40.77365875,
      -73.96230316,
      40.77204895,
      5.5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 15:47:42 +00:00',
      '2015-01-15 15:52:40 +00:00',
      1,
      0.6,
      -73.96900177,
      40.76102829,
      -73.97166443,
      40.75520325,
      5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 15:47:43 +00:00',
      '2015-01-15 16:02:21 +00:00',
      1,
      2,
      -73.98990631,
      40.77584076,
      -73.97659302,
      40.7581749,
      11,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 15:47:43 +00:00',
      '2015-01-15 17:12:21 +00:00',
      1,
      17.2,
      -73.97954559,
      40.74938965,
      -73.77823639,
      40.64494324,
      52,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 15:47:43 +00:00',
      '2015-01-15 15:52:40 +00:00',
      1,
      0.7,
      -73.96670532,
      40.80432129,
      -73.97161102,
      40.79508209,
      5.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 15:47:43 +00:00',
      '2015-01-15 16:01:34 +00:00',
      1,
      1.6,
      -73.97691345,
      40.75243378,
      -73.9618454,
      40.76618576,
      10.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 15:47:43 +00:00',
      '2015-01-15 16:05:15 +00:00',
      1,
      5.6,
      -73.97419739,
      40.75210953,
      -74.01583099,
      40.70493317,
      19,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 15:47:44 +00:00',
      '2015-01-15 15:51:25 +00:00',
      3,
      0.6,
      -73.98048401,
      40.73053741,
      -73.99012756,
      40.73469543,
      4.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 11:08:28 +00:00',
      '2015-01-15 11:22:08 +00:00',
      5,
      1.02,
      -73.96199036,
      40.76301956,
      -73.97537231,
      40.76025009,
      9.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 11:08:28 +00:00',
      '2015-01-15 11:12:03 +00:00',
      2,
      0.71,
      -73.96024323,
      40.81326675,
      -73.96228027,
      40.80551529,
      5,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 11:08:28 +00:00',
      '2015-01-15 11:45:14 +00:00',
      4,
      3.29,
      -73.99386597,
      40.72647858,
      -73.97525024,
      40.76517868,
      22.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 11:08:28 +00:00',
      '2015-01-15 11:16:27 +00:00',
      6,
      1.69,
      -73.97033691,
      40.76505661,
      -73.95837402,
      40.78314972,
      7.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 11:08:29 +00:00',
      '2015-01-15 11:31:28 +00:00',
      1,
      3.45,
      -73.97387695,
      40.76417923,
      -73.99996185,
      40.72422028,
      16.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 11:08:29 +00:00',
      '2015-01-15 11:19:43 +00:00',
      1,
      1.91,
      -73.98752594,
      40.7325058,
      -74.00524902,
      40.71485138,
      9.5,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 11:08:30 +00:00',
      '2015-01-15 11:20:15 +00:00',
      1,
      1.71,
      -73.99472046,
      40.75059509,
      -73.97688293,
      40.76406479,
      9.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 11:08:30 +00:00',
      '2015-01-15 11:23:16 +00:00',
      1,
      1.92,
      -73.97637177,
      40.76343155,
      -73.99304962,
      40.74539948,
      11,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 11:08:30 +00:00',
      '2015-01-15 11:10:32 +00:00',
      5,
      0.27,
      -73.95477295,
      40.76545715,
      -73.95536804,
      40.76841354,
      3.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 11:08:30 +00:00',
      '2015-01-15 11:22:21 +00:00',
      1,
      1.44,
      -73.95503235,
      40.77841568,
      -73.97670746,
      40.78928375,
      10,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 11:08:31 +00:00',
      '2015-01-15 11:17:10 +00:00',
      1,
      2.17,
      -73.95035553,
      40.77127838,
      -73.96971893,
      40.75237656,
      9,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 11:08:31 +00:00',
      '2015-01-15 11:22:19 +00:00',
      1,
      2.13,
      -73.95650482,
      40.77070236,
      -73.9798584,
      40.75445557,
      10.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 11:08:31 +00:00',
      '2015-01-15 12:07:41 +00:00',
      1,
      18.23,
      -73.78193665,
      40.64471054,
      -73.99067688,
      40.72071075,
      52,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 11:08:31 +00:00',
      '2015-01-15 11:24:35 +00:00',
      1,
      1.01,
      -73.97892761,
      40.75037766,
      -73.99127197,
      40.75544739,
      10.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 11:08:31 +00:00',
      '2015-01-15 11:14:02 +00:00',
      5,
      0.88,
      -73.9934845,
      40.73516464,
      -73.99437714,
      40.74361038,
      5.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 11:08:33 +00:00',
      '2015-01-15 11:16:14 +00:00',
      5,
      1.51,
      -73.97502899,
      40.76099014,
      -73.98868561,
      40.74270248,
      7.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 11:08:33 +00:00',
      '2015-01-15 11:52:22 +00:00',
      1,
      8.14,
      -73.95440674,
      40.76395798,
      -73.8562851,
      40.75259018,
      33.5,
      false,
      'apple tree'
    ],
    [
      '2015-01-15 11:08:33 +00:00',
      '2015-01-15 11:48:04 +00:00',
      5,
      15.13,
      -73.95636749,
      40.78319931,
      -73.98403931,
      40.63248825,
      46,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 11:08:34 +00:00',
      '2015-01-15 11:30:55 +00:00',
      1,
      2.47,
      -74.00182343,
      40.75087738,
      -73.9638443,
      40.77070618,
      15.5,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 11:08:34 +00:00',
      '2015-01-15 11:15:45 +00:00',
      1,
      1.27,
      -73.97843933,
      40.74962616,
      -73.99088287,
      40.73462677,
      7,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 14:19:58 +00:00',
      '2015-01-15 14:30:43 +00:00',
      3,
      1.9,
      -73.9719696,
      40.78208542,
      -73.9462738,
      40.78117752,
      9.5,
      true,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 14:19:59 +00:00',
      '2015-01-15 14:26:18 +00:00',
      1,
      1.4,
      -73.96221161,
      40.75906372,
      -73.95623016,
      40.77560425,
      6.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 14:20:00 +00:00',
      '2015-01-15 14:29:24 +00:00',
      1,
      1,
      -73.98879242,
      40.74028015,
      -73.99024963,
      40.72889328,
      7.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 14:20:00 +00:00',
      '2015-01-15 14:24:37 +00:00',
      1,
      0.7,
      -73.96638489,
      40.80471039,
      -73.97330475,
      40.79518509,
      5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 14:20:00 +00:00',
      '2015-01-15 14:25:07 +00:00',
      1,
      0.6,
      -73.98246765,
      40.77352142,
      -73.9892807,
      40.76781464,
      5.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 14:20:00 +00:00',
      '2015-01-15 15:08:06 +00:00',
      1,
      20.8,
      -73.97194672,
      40.75946808,
      -73.78960419,
      40.64704132,
      52,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 14:20:01 +00:00',
      '2015-01-15 14:30:10 +00:00',
      1,
      1.5,
      -73.97073364,
      40.76262283,
      -73.95559692,
      40.7793045,
      8.5,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 14:20:02 +00:00',
      '2015-01-15 14:44:57 +00:00',
      1,
      4.9,
      -73.95665741,
      40.76708221,
      -73.96069336,
      40.81632614,
      20.5,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 14:20:02 +00:00',
      '2015-01-15 14:25:28 +00:00',
      1,
      1.5,
      -73.97408295,
      40.77892685,
      -73.95641327,
      40.7840538,
      6.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 14:20:02 +00:00',
      '2015-01-15 15:05:17 +00:00',
      1,
      16.2,
      -73.97773743,
      40.76435471,
      -73.79027557,
      40.64681244,
      52,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 14:20:04 +00:00',
      '2015-01-15 14:30:20 +00:00',
      1,
      0.9,
      -73.96961975,
      40.75938034,
      -73.9821701,
      40.76047134,
      7.5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 14:20:06 +00:00',
      '2015-01-15 14:23:11 +00:00',
      1,
      0.2,
      -73.97293091,
      40.79558563,
      -73.97042847,
      40.79651642,
      4,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 14:20:06 +00:00',
      '2015-01-15 14:25:16 +00:00',
      1,
      0.7,
      -73.74542999,
      40.58639145,
      -73.74523926,
      40.58619308,
      5.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 14:20:06 +00:00',
      '2015-01-15 14:25:32 +00:00',
      1,
      0.7,
      -73.98985291,
      40.73440552,
      -73.99806976,
      40.73797989,
      5.5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 14:20:07 +00:00',
      '2015-01-15 14:31:34 +00:00',
      1,
      1.2,
      -73.96231842,
      40.77913666,
      -73.9730835,
      40.76346207,
      9,
      true,
      'orange peel'
    ],
    [
      '2015-01-15 14:20:08 +00:00',
      '2015-01-15 14:43:34 +00:00',
      1,
      4.3,
      -73.97229004,
      40.74654388,
      -74.00812531,
      40.71973419,
      19,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 14:20:08 +00:00',
      '2015-01-15 14:23:44 +00:00',
      1,
      0.9,
      -74.00809479,
      40.73937225,
      -74.00430298,
      40.72974777,
      5,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 11:09:27 +00:00',
      '2015-01-15 11:18:50 +00:00',
      2,
      0.82,
      -73.98420715,
      40.74626923,
      -73.97663116,
      40.75596619,
      7,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 11:09:28 +00:00',
      '2015-01-15 11:21:16 +00:00',
      5,
      1.48,
      -73.9804306,
      40.74560547,
      -73.98104858,
      40.72947693,
      9,
      true,
      'banana peel'
    ],
    [
      '2015-01-15 11:09:28 +00:00',
      '2015-01-15 11:17:09 +00:00',
      1,
      0.85,
      -73.98188782,
      40.74059677,
      -73.98110199,
      40.74951172,
      6.5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 11:09:28 +00:00',
      '2015-01-15 11:12:01 +00:00',
      1,
      0.39,
      -73.96213531,
      40.76808167,
      -73.96128845,
      40.76436234,
      3.5,
      false,
      'banana peel'
    ],
    [
      '2015-01-15 11:09:28 +00:00',
      '2015-01-15 11:16:34 +00:00',
      4,
      1.08,
      -73.98171997,
      40.75831985,
      -73.96775055,
      40.76005936,
      6.5,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 11:09:32 +00:00',
      '2015-01-15 11:45:33 +00:00',
      1,
      4.35,
      -73.92948914,
      40.75658035,
      -73.99256134,
      40.75829315,
      23,
      false,
      'orange peel'
    ],
    [
      '2015-01-15 11:09:32 +00:00',
      '2015-01-15 11:27:44 +00:00',
      1,
      0.87,
      -73.97641754,
      40.74801636,
      -73.97517395,
      40.74153519,
      11.5,
      true,
      'apple tree'
    ],
    [
      '2015-01-15 11:09:32 +00:00',
      '2015-01-15 11:16:36 +00:00',
      1,
      0.96,
      -73.96311951,
      40.76504517,
      -73.95355225,
      40.77010727,
      6.5,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 11:09:34 +00:00',
      '2015-01-15 11:28:22 +00:00',
      1,
      1.2,
      -73.99105835,
      40.74952698,
      -73.97509003,
      40.74176025,
      12,
      false,
      'mango mint pineapple juice'
    ],
    [
      '2015-01-15 11:09:34 +00:00',
      '2015-01-15 11:39:55 +00:00',
      1,
      7.47,
      -74.01488495,
      40.71375275,
      -73.97367859,
      40.76446533,
      28,
      true,
      'orange peel'
    ]
  ]
};

export const sampleTripDataConfig = {
  version: 'v1',
  config: {
    visState: {
      layers: [
        {
          type: 'heatmap',
          config: {
            dataId: 'test_trip_data',
            columns: {
              lat: 'pickup_latitude',
              lng: 'pickup_longitude'
            },
            isVisible: true
          }
        },
        {
          type: 'point',
          config: {
            dataId: 'test_trip_data',
            columns: {
              lat: 'pickup_latitude',
              lng: 'pickup_longitude'
            },
            color: [255, 0, 0],
            label: 'pickup',
            isVisible: true,
            visConfig: {
              colorRange: {
                colors: ['#FF000', '#00FF00', '#0000FF', '#555555', '#111111', '#222222'],
                colorMap: [
                  ['apple tree', '#FF000'],
                  ['banana peel', '#00FF00'],
                  ['banana peel 2', '#0000FF'],
                  ['mango mint pineapple juice', '#555555'],
                  ['orange peel', '#111111'],
                  ['orange peel 0', '#222222']
                ]
              }
            }
          },
          visualChannels: {
            colorField: {
              name: 'fare_type',
              type: 'string'
            },
            colorScale: 'custom'
          }
        },
        {
          type: 'point',
          config: {
            dataId: 'test_trip_data',
            columns: {
              lat: 'dropoff_latitude',
              lng: 'dropoff_longitude'
            },
            color: [0, 0, 255],
            label: 'dropoff',
            isVisible: true
          }
        },
        {
          type: 'cluster',
          config: {
            dataId: 'test_trip_data',
            columns: {
              lat: 'pickup_latitude',
              lng: 'pickup_longitude'
            },
            isVisible: true
          }
        },
        {
          type: 'arc',
          config: {
            dataId: 'test_trip_data',
            label: 'pickup -> dropoff',
            columns: {
              lat0: 'pickup_latitude',
              lng0: 'pickup_longitude',
              lat1: 'dropoff_latitude',
              lng1: 'dropoff_longitude'
            },
            color: [255, 0, 0],
            isVisible: true,
            visConfig: {
              targetColor: [0, 0, 255]
            }
          }
        }
      ],
      filters: [
        {
          dataId: 'test_trip_data',
          name: 'tpep_pickup_datetime',
          view: 'enlarged'
        },
        {
          dataId: 'test_trip_data',
          name: 'passenger_count'
        },
        {
          dataId: 'test_trip_data',
          name: 'fare_type',
          value: ['orange peel', 'apple tree']
        },
        {
          dataId: 'test_trip_data',
          name: 'is_completed',
          value: true
        }
      ]
    },
    mapStyle: {
      styleType: '41fv96u',
      visibleLayerGroups: {
        label: false,
        road: false,
        border: false,
        building: true,
        water: true,
        land: true,
        '3d building': false
      },
      mapStyles: {
        '41fv96u': {
          accessToken: null,
          custom: true,
          icon: 'https://api.mapbox.com/styles/v1/MAPBOX_USER/cjg0ks54x300a2squ8fr9vhvq/static/-122.3391,37.7922,9,0,0/400x300?access_token=ACCESS_TOKEN&logo=false&attribution=false',
          id: '41fv96u',
          label: 'Outdoors',
          url: 'mapbox://styles/MAPBOX_USER/cjhnxdcfy4ug62sn6qdfjutob'
        }
      }
    }
  }
};
