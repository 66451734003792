// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Base from './base';

export default class Edit extends Component {
  static propTypes = {
    /** Set the height of the icon, ex. '16px' */
    height: PropTypes.string
  };

  static defaultProps = {
    height: '16px',
    viewBox: '0 0 16 16',
    predefinedClassName: 'data-ex-icons-edit'
  };

  render() {
    return (
      <Base {...this.props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8155 2.78902C12.0005 2.60397 12.2515 2.5 12.5132 2.5C12.6428 2.5 12.7711 2.52552 12.8908 2.57512C13.0106 2.62471 13.1194 2.69739 13.211 2.78902C13.3026 2.88066 13.3753 2.98944 13.4249 3.10916C13.4745 3.22889 13.5 3.3572 13.5 3.48679C13.5 3.61638 13.4745 3.7447 13.4249 3.86442C13.3753 3.98414 13.3026 4.09293 13.211 4.18456L4.54791 12.8476L2.68719 13.3128L3.15237 11.4521L11.8155 2.78902ZM12.5132 1.5C11.9863 1.5 11.4809 1.70932 11.1083 2.08192L2.34734 10.8429C2.28326 10.907 2.2378 10.9873 2.21582 11.0752L1.51494 13.8787C1.47234 14.0491 1.52227 14.2294 1.64646 14.3536C1.77065 14.4777 1.95089 14.5277 2.12128 14.4851L4.9248 13.7842C5.01272 13.7622 5.09301 13.7168 5.15709 13.6527L13.9181 4.89167C14.1026 4.70718 14.2489 4.48815 14.3488 4.2471C14.4486 4.00606 14.5 3.7477 14.5 3.48679C14.5 3.22588 14.4486 2.96753 14.3488 2.72648C14.2489 2.48543 14.1026 2.26641 13.9181 2.08192C13.7336 1.89743 13.5146 1.75108 13.2735 1.65124C13.0325 1.55139 12.7741 1.5 12.5132 1.5ZM8 13.5C7.72386 13.5 7.5 13.7239 7.5 14C7.5 14.2761 7.72386 14.5 8 14.5H14C14.2761 14.5 14.5 14.2761 14.5 14C14.5 13.7239 14.2761 13.5 14 13.5H8Z"
        />
      </Base>
    );
  }
}
