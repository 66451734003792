// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React, {Component} from 'react';
import {Icons} from '@kepler.gl/components';
import PropTypes from 'prop-types';

const style = {
  background: 'black'
};
export default class FoursquareIcon extends Component {
  static propTypes = {
    /** Set the height of the icon, ex. '16px' */
    height: PropTypes.string,
    colors: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    width: '64px',
    fill: 'black',
    predefinedClassName: 'cloud-provider-studio-icon',
    totalColor: 1,
    style
  };

  render() {
    return (
      <Icons.IconWrapper {...this.props} viewBox="0 -10 84 120">
        <path
          d="M2.30368 0H33.1681V5.341H8.33118V18.5748H30.1695V23.7674H8.33118V43.5885H2.30368V0Z"
          fill="white"
        />
        <path
          d="M5.66576 84.6965C5.80711 87.1692 6.40279 89.177 7.4528 90.72C9.45187 93.6081 12.9755 95.0521 18.0236 95.0521C20.2852 95.0521 22.3449 94.7356 24.2026 94.1026C27.7969 92.8762 29.594 90.6804 29.594 87.5154C29.594 85.1416 28.8368 83.4503 27.3223 82.4414C25.7877 81.4524 23.3848 80.5919 20.1136 79.86L14.0861 78.5247C10.1485 77.6543 7.36194 76.6949 5.72633 75.6465C2.89937 73.8266 1.48588 71.1067 1.48588 67.4867C1.48588 63.5699 2.86908 60.3554 5.63547 57.8432C8.40186 55.3309 12.3192 54.0748 17.3876 54.0748C22.0521 54.0748 26.0098 55.1826 29.2608 57.3981C32.532 59.5939 34.1676 63.115 34.1676 67.9614H28.5036C28.2007 65.6272 27.5546 63.837 26.5651 62.5907C24.7276 60.3159 21.6078 59.1784 17.2058 59.1784C13.6519 59.1784 11.0976 59.9104 9.54274 61.3742C7.98791 62.838 7.21049 64.5392 7.21049 66.4778C7.21049 68.6142 8.11916 70.1769 9.9365 71.166C11.1279 71.799 13.8236 72.5903 18.0236 73.5398L24.2632 74.9344C27.2719 75.607 29.594 76.5268 31.2296 77.6939C34.0566 79.7314 35.4701 82.6887 35.4701 86.5659C35.4701 91.3926 33.6729 94.8444 30.0786 96.9215C26.5045 98.9985 22.3449 100.037 17.5996 100.037C12.0668 100.037 7.7355 98.6524 4.60564 95.883C1.47579 93.1333 -0.0588526 89.4045 0.00172532 84.6965H5.66576Z"
          fill="white"
        />
        <path
          d="M83.8112 98.6425L80.7823 102.233L73.9067 97.0995C72.2509 97.9897 70.4538 98.7018 68.5153 99.2359C66.597 99.77 64.497 100.037 62.2152 100.037C55.3093 100.037 49.8977 97.8215 45.9803 93.3905C42.5274 89.0781 40.8009 83.6778 40.8009 77.1895C40.8009 71.2946 42.2952 66.2503 45.2837 62.0566C49.1203 56.6761 54.7944 53.9858 62.3061 53.9858C70.161 53.9858 75.9765 56.4585 79.7525 61.4039C82.7006 65.2612 84.1747 70.1967 84.1747 76.2103C84.1747 79.0193 83.8213 81.7194 83.1146 84.3108C82.0444 88.2671 80.2371 91.4915 77.6929 93.9839L83.8112 98.6425ZM62.9421 94.7554C64.1941 94.7554 65.3653 94.6763 66.4557 94.518C67.5461 94.34 68.4951 94.0037 69.3028 93.5092L64.4263 89.7705L67.4552 86.1208L73.2707 90.542C75.1082 88.4847 76.35 86.1801 76.9962 83.6283C77.6626 81.0765 77.9957 78.6335 77.9957 76.2993C77.9957 71.1759 76.6226 67.0515 73.8764 63.926C71.1504 60.8005 67.4148 59.2378 62.6695 59.2378C57.8637 59.2378 54.0574 60.7412 51.2506 63.748C48.4438 66.735 47.0404 71.344 47.0404 77.5752C47.0404 82.8173 48.3833 86.9912 51.0689 90.0969C53.7747 93.2026 57.7324 94.7554 62.9421 94.7554Z"
          fill="white"
        />
      </Icons.IconWrapper>
    );
  }
}
