// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import document from 'global/document';
import {Provider} from 'react-redux';
import {browserHistory} from 'react-router';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import {syncHistoryWithStore} from 'react-router-redux';
import store from './store';
import App from './app';
import {buildAppRoutes} from './utils/routes';
import {jwtDecode} from 'jwt-decode';

const history = syncHistoryWithStore(browserHistory, store);

const appRoute = buildAppRoutes(App);

const Root = () => {
  useEffect(() => {
    const handleToken = () => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('jwt') || sessionStorage.getItem('authToken');
    
      if (!token) {
        window.location.href = 'https://jpn.geobit.ai';
        return;
      }
    
      try {
        const decoded = jwtDecode(token);
    
        if (!decoded?.user_id) {
          window.location.href = 'https://jpn.geobit.ai';
          return;
        }
        sessionStorage.setItem('authToken', token);
        window.history.replaceState({}, document.title, window.location.origin + window.location.pathname);
      } catch (error) {
        console.error('Invalid token:', error);
        window.location.href = 'https://jpn.geobit.ai';
      }
    };

    handleToken();
  }, []); // Empty dependency array ensures this runs only once

  return (
    <Provider store={store}>
      <Router history={history}>
        <Routes>
          <Route
            path="/"
            element={
              (
                <App>
                  {appRoute}
                </App>
              )
            }
          />
        </Routes>
      </Router>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<Root />);
