export const TASK_LIST = '1. Change the basemap style.\n2. Load data from url.';

export const WELCOME_MESSAGE = `Hi, I am GeoBit AI Assistant!\nHere are some tasks I can help you with:\n\n${TASK_LIST}`;

export const INSTRUCTIONS = `You are a helpful assistant that can answer questions and help with tasks. If you can use tools, please use them. If the parameters of functions are not provided, please ask user to specify them. Otherwise, just answer the question with plain textdirectly. Do not include any programming code in your response. You can do the following tasks: ${TASK_LIST}`;

export const ASSISTANT_NAME = 'geobit-ai-assistant';

export const ASSISTANT_DESCRIPTION = 'A GeoBit AI Assistant';

export const ASSISTANT_VERSION = '0.0.1-1';

export const OPENAI_API_KEY = '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';
